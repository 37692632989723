import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';

import { measurement } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { IPersonInfo, IPersonMeasurement } from 'interface/IPersonInfo';
import { DiffDateTable } from 'service/dateService';
import { formatValuesMeasurement } from 'service/formatValuesMeasurementService';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo;
}

export enum eType {
  Icon,
  Title,
}

export default function CardBody({ person }: Readonly<IProps>) {
  const measurementTypes = [
    'stethoscope',
    'glucose',
    'device_thermostat',
    'ecg_heart',
    'pulmonology',
    'directions_run',
  ];

  function returnNameValueIcon(pm: IPersonMeasurement) {
    switch (pm.measurementType) {
      case measurement.heartRate:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>ecg_heart</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      case measurement.bloodPressure:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>stethoscope</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      case measurement.oximetry:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>pulmonology</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      case measurement.bloodSugar:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>glucose</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      case measurement.bodyTemperature:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>device_thermostat</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      case measurement.steps:
        return (
          <>
            <span className={'material-symbols-outlined color-primary'}>directions_run</span>
            {formatValuesMeasurement(pm.value, pm.measurementType)}
          </>
        );
      default:
        return '';
    }
  }
  const { userInfo } = useAuthContext();
  return (
    <Box className={styles.tableMeasurementContainer}>
      <Table className={styles.measurementTable} size="small">
        <TableBody className={styles.tableCell}>
          {person?.measurements.length > 0
            ? person.measurements
              .sort((a, b) => a.measurementType.localeCompare(b.measurementType))
              .map((pm) => (
                <TableRow tabIndex={0} className={styles.tableRow} key={pm.measurementType}>
                  <TableCell>
                    <div className={styles.measurementTypeContainer}>
                      {returnNameValueIcon(pm)}
                    </div>
                    <div className={styles.cardLastSeen}>
                      {DiffDateTable(pm.lastUpdate, userInfo.timeZone)}
                    </div>
                  </TableCell>
                </TableRow>
              ))
            : measurementTypes.map((type, index) => (
              <TableRow tabIndex={0} className={styles.tableRow} key={index}>
                <TableCell>
                  <div className={styles.measurementTypeContainer}>
                    <span key={index} className={'material-symbols-outlined color-primary'}>
                      {type}
                    </span>
                    <span>{'------'}</span>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}

import { Box } from '@mui/material';

import { ReactComponent as TakeCare } from 'assets/logo_takecare.svg';
import { ReactComponent as TakeCareMobile } from 'assets/logo_takecare_mobile.svg';
import ButtonAccount from 'components/Header/ButtonAccount';
import ChangeMapView from 'components/Header/ChangeMapView';
import SearchInput from 'components/Header/SearchInput';
import useWindow from 'hooks/useWindow';
import OrganizationDrop from './OrganizationDrop';
import styles from './styles.module.scss';

export default function Header() {
  const { isMobile } = useWindow();

  return (
    <>
      <Box className={styles.boxHeader}>
        {isMobile ? (
          <TakeCareMobile className={styles.logoMobile} />
        ) : (
          <TakeCare className={styles.logo} />
        )}
        <SearchInput />
        <OrganizationDrop />
        <div className={styles.buttonsAndMapView}>
          <ChangeMapView />
          <ButtonAccount />
        </div>
      </Box>
      <div className={styles.divider} />
    </>
  );
}

import { Drawer } from '@mui/material';
import CardsMeasurement from 'components/CardsMeasurement';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import styles from './styles.module.scss';

export default function MenuCardsMeasurement() {
  const { currentMapView } = useCurrentViewContext();

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={
        currentMapView === MapViewEnum.MENU_CARDS ||
        currentMapView === MapViewEnum.MENU_CARDS_DETAILS
      }
      className={styles.drawerContainer}
    >
      <CardsMeasurement />
    </Drawer>
  );
}

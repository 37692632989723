import { InputBase, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import { useDataContext } from 'context/DataMapContext';
import styles from './styles.module.scss';

export default function SearchInput() {
  const [_searchPerson, setSearchPerson] = useState<string>('');
  const { loadData } = useDataContext();

  function setVisibleMarker(search: string) {
    loadData(search);
  }

  useEffect(() => {
    setVisibleMarker(_searchPerson);
  }, [_searchPerson]);

  return (
    <Paper component="form" className={styles.searchContainer}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Pesquisar"
        inputProps={{ 'aria-label': 'Pesquisar' }}
        onChange={(e) => setSearchPerson(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="Pesquisar">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

import api from 'api';

function getVersion() {
  return api.get('/Version');
}
const versionService = {
  getVersion,
};

export default versionService;

import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';

import { measurement } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { IMeasurementTypes } from 'interface/IMeasurement';
import { IPersonInfo } from 'interface/IPersonInfo';
import { DiffDateTable, formatDateToHoursAndMinutes } from 'service/dateService';
import { formatValuesMeasurement } from 'service/formatValuesMeasurementService';
import measurementService from 'service/measurementService';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo | null;
  setMeasurementType: Dispatch<SetStateAction<string>>;
  measurementType: string;
}

export enum eType {
  Icon,
  Title,
}

export function returnNameValueIcon(measurementType: string, type: eType) {
  switch (measurementType) {
    case measurement.heartRate:
      switch (type) {
        case eType.Title:
          return 'Frequência Cardíaca';
        case eType.Icon:
          return <span className={'material-symbols-outlined color-primary'}>ecg_heart</span>;
        default:
          return '';
      }
    case measurement.bloodPressure:
      switch (type) {
        case eType.Title:
          return 'Pressão Arterial';
        case eType.Icon:
          return <span className={'material-symbols-outlined color-primary'}>stethoscope</span>;
        default:
          return '';
      }
    case measurement.oximetry:
      switch (type) {
        case eType.Title:
          return 'Oximetria';
        case eType.Icon:
          return <span className={'material-symbols-outlined color-primary'}>pulmonology</span>;
        default:
          return '';
      }
    case measurement.bloodSugar:
      switch (type) {
        case eType.Title:
          return 'Glicose';
        case eType.Icon:
          return <span className={'material-symbols-outlined color-primary'}>glucose</span>;
        default:
          return '';
      }
    case measurement.bodyTemperature:
      switch (type) {
        case eType.Title:
          return 'Temperatura Corporal';
        case eType.Icon:
          return (
            <span className={'material-symbols-outlined color-primary'}>device_thermostat</span>
          );
        default:
          return '';
      }
    case measurement.steps:
      switch (type) {
        case eType.Title:
          return 'Passos';
        case eType.Icon:
          return <span className={'material-symbols-outlined color-primary'}>directions_run</span>;
        default:
          return '';
      }
    default:
      return '';
  }
}

export default function TableMeasurement({
  person,
  setMeasurementType,
  measurementType,
}: Readonly<IProps>) {
  const [loadingMeasurementTypes, setLoadingMeasurementTypes] = useState<string[]>([]);
  const { userInfo } = useAuthContext();
  function onTableRowClick(currMeasurementType: string) {
    if (measurementType === currMeasurementType) {
      setMeasurementType('');
    } else {
      setMeasurementType(currMeasurementType);
    }
  }

  return (
    <Box className={styles.tableMeasurementContainer}>
      <Table className={styles.measurementTable} size="small">
        <TableBody>
          {person?.measurements
            .sort((a, b) => a.measurementType.localeCompare(b.measurementType))
            .map((pm) => (
              <TableRow
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  if (e.key === 'Enter' || e.key === '') {
                    onTableRowClick(pm.measurementType);
                  }
                }}
                className={
                  measurementType === pm.measurementType
                    ? `${styles.tableRow} ${styles.tableRowSelected}`
                    : styles.tableRow
                }
                key={pm.measurementType}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  onTableRowClick(pm.measurementType);
                }}
              >
                <TableCell>
                  <div className={styles.measurementTypeContainer}>
                    {returnNameValueIcon(pm.measurementType, eType.Icon)}
                    {returnNameValueIcon(pm.measurementType, eType.Title)}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {formatValuesMeasurement(pm.value, pm.measurementType)}
                </TableCell>
                <TableCell align="right">
                  <Tooltip
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-20, 0],
                          },
                        },
                      ],
                    }}
                    title={
                      <p style={{ padding: 0 }}>
                        {' '}
                        {DiffDateTable(pm.lastUpdate, userInfo.timeZone)}{' '}
                      </p>
                    }
                    placement="right-start"
                  >
                    <div>{formatDateToHoursAndMinutes(pm.lastUpdate, userInfo.timeZone)}</div>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" sx={{ padding: '0!important' }}>
                  {pm.measurementType !== measurement.steps ? (
                    !loadingMeasurementTypes.includes(pm.measurementType) ? (
                      <div className={styles.buttonsContainer}>
                        <Tooltip title="Medir agora" placement="right">
                          <span>
                            <IconButton
                              disabled={person.online ? false : true}
                              aria-label="Medir Agora"
                              onClick={async (e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();

                                setLoadingMeasurementTypes((old) => [...old, pm.measurementType]);

                                const mt: IMeasurementTypes = {
                                  measurementTypes: [],
                                };
                                mt.measurementTypes.push(pm.measurementType);
                                await measurementService.measurementCreate(person.personId, mt);

                                setTimeout(() => {
                                  setLoadingMeasurementTypes((old) =>
                                    old.filter((ol) => ol !== pm.measurementType)
                                  );
                                }, 2000);
                              }}
                            >
                              <SyncIcon
                                className={person.online ? styles.buttonIcon : styles.buttonIconOff}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    ) : (
                      <Box
                        sx={{
                          height: '36px',
                          width: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress
                          sx={{
                            height: '20px!important',
                            width: '20px!important',
                            color: '#036666',
                            fill: '#036666',
                          }}
                        />
                      </Box>
                    )
                  ) : (
                    <Box sx={{ width: '36px', height: '36px' }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}

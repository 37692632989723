import { useState } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

import BaseModal, { ModalProps } from 'components/BaseModal';
import styles from './styles.module.scss';

interface ConfigModalProps extends ModalProps {}

export default function ConfigModal({ isOpen, onClose }: ConfigModalProps) {
  const [isDevicesSyncChecked, setIsDevicesSyncChecked] = useState<boolean>(true);

  function handleChangeDevicesSync(event: React.ChangeEvent<HTMLInputElement>) {
    setIsDevicesSyncChecked(event.target.checked);
  }

  return (
    <BaseModal title="Configurações do Sistema" isOpen={isOpen} onClose={onClose} showSaveButton>
      <div className={styles.configContainer}>
        <FormControlLabel
          control={<Checkbox checked={isDevicesSyncChecked} onChange={handleChangeDevicesSync} />}
          label="Sincronização entre dispositivos"
        />
      </div>
    </BaseModal>
  );
}

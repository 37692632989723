import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { HeaderKeys } from 'components/TableMeasurementRealTime/TableHeader';
import config from 'config/config';
import { Events, measurement, storageParams } from 'config/enum';
import { IPersonInfo } from 'interface/IPersonInfo';
import { formatValuesMeasurementHistory } from 'service/formatValuesMeasurementService';
import { default as Connector } from 'service/signalRConnectionService';

interface DataMapContextProps {
  children: React.ReactNode;
}

interface IDashBoardItems {
  items: IPersonInfo[];
}

interface IDataMapContext {
  organization: (empresa: string) => void;
  empresa: string;
  loadData: (search: string) => void;
  search: string;
  orderDirection: 'asc' | 'desc' | undefined;
  handleRequestSort: (key: keyof typeof HeaderKeys | undefined) => void;
  orderBy: keyof typeof HeaderKeys | undefined;
  filteredPersons: IPersonInfo[];
  selectedPerson: IPersonInfo | null;
  setSelectedPerson: Dispatch<SetStateAction<IPersonInfo | null>>;
}

const DataContext = createContext<IDataMapContext | undefined>(undefined);

export function compareTwoValues(x: string | number | undefined, y: string | number | undefined) {
  if (x !== undefined && y !== undefined) {
    if (x > y) {
      return 1;
    }
    if (y > x) {
      return -1;
    }
    return 0;
  }
  return 0;
}

export function compareTwoMeasurementTypeValues(
  x: IPersonInfo,
  y: IPersonInfo,
  measurementType: string
) {
  const mma = x.measurements.find((m) => m.measurementType === measurementType);
  const mmb = y.measurements.find((m) => m.measurementType === measurementType);

  return compareTwoValues(
    formatValuesMeasurementHistory(mma?.value, measurementType),
    formatValuesMeasurementHistory(mmb?.value, measurementType)
  );
}

export function comparator(
  a: IPersonInfo,
  b: IPersonInfo,
  order: keyof typeof HeaderKeys | undefined
) {
  if (a.activityPriority === b.activityPriority) {
    switch (order) {
      case 'nome':
        return compareTwoValues(a.name, b.name);
      case 'idade':
        return compareTwoValues(a.age, b.age);
      case 'frequenciaCardiaca':
        return compareTwoMeasurementTypeValues(a, b, measurement.heartRate);
      case 'pressaoArterial':
        return compareTwoMeasurementTypeValues(a, b, measurement.bloodPressure);
      case 'oximetria':
        return compareTwoMeasurementTypeValues(a, b, measurement.oximetry);
      case 'glicose':
        return compareTwoMeasurementTypeValues(a, b, measurement.bloodSugar);
      case 'temperaturaCorporal':
        return compareTwoMeasurementTypeValues(a, b, measurement.bodyTemperature);
      default:
        return 0;
    }
  }
  return a.activityPriority > b.activityPriority ? -1 : 0;
}

function searchPersonName(person: IPersonInfo[], nameSearch: string) {
  return person.filter(function (p) {
    return (
      removeAccentuation(p.name)
        .toLowerCase()
        .includes(removeAccentuation(nameSearch.toLowerCase())) ||
      p.occurrencesTypes.includes(Events.Sos) ||
      p.occurrencesTypes.includes(Events.Fall)
    );
  });
}

export function removeAccentuation(value: string) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const DataContextProvider = ({ children }: DataMapContextProps) => {
  const [data, setData] = useState<IPersonInfo[]>([]);
  const [persons, setPersons] = useState<IPersonInfo[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<IPersonInfo | null>(null);
  const [filteredPersons, setFilteredPersons] = useState<IPersonInfo[]>([]);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>();
  const [orderBy, setOrderBy] = useState<keyof typeof HeaderKeys>();
  const [search, setSearch] = useState<string>('');

  const [empresa, setEmpresa] = useState<string>(
    sessionStorage.getItem(storageParams.organization) ?? ''
  );

  const handleRequestSort = useCallback(
    (key: keyof typeof HeaderKeys | undefined) => {
      setOrderBy(key);
      setOrderDirection(() => {
        if (orderDirection === undefined) {
          return 'asc';
        }
        if (orderBy === key && orderDirection === 'asc') {
          return 'desc';
        }
        if (orderBy === key && orderDirection === 'desc') {
          setOrderBy(undefined);
          return undefined;
        }
        return 'asc';
      });

      setData((prevData) => {
        const orderedValues: IPersonInfo[] = [];

        if (orderDirection === 'asc') {
          orderedValues.push(...prevData.sort((a, b) => comparator(a, b, orderBy)));
        } else {
          orderedValues.push(...prevData.sort((a, b) => -comparator(a, b, orderBy)));
        }
        return orderedValues;
      });
    },
    [orderBy, orderDirection]
  );

  const returnListPersons = useCallback(
    function (dataPerson: IPersonInfo[]) {
      setPersons((prevPersons) => {
        dataPerson.forEach((person) => {
          const index = prevPersons?.findIndex((x) => x.personId === person.personId);
          const personExist = index >= 0;

          if (personExist) {
            prevPersons[index] = person;
          } else {
            prevPersons.push(person);
          }
        });
        prevPersons.forEach((x, index) => {
          if (
            prevPersons &&
            x.organizationId !== sessionStorage.getItem(storageParams.organization)
          ) {
            prevPersons.slice(index);
          }
        });
        const orderedValues: IPersonInfo[] = [];

        if (orderDirection === 'asc') {
          orderedValues.push(...prevPersons.sort((a, b) => comparator(a, b, orderBy)));
        } else {
          orderedValues.push(...prevPersons.sort((a, b) => -comparator(a, b, orderBy)));
        }

        return orderedValues;
      });
    },
    [orderBy, orderDirection]
  );

  useEffect(() => {
    returnListPersons(data);
  }, [data]);

  useEffect(() => {
    if (!search) {
      setFilteredPersons(persons);
    } else {
      const filter = searchPersonName(persons, search);
      setFilteredPersons(filter);
    }
  }, [search, persons]);

  useEffect(() => {
    const { events } = Connector();

    if (events) {
      events((data) => {
        if (data) {
          const personArray: IPersonInfo[] = [];
          const dashData: IDashBoardItems = JSON.parse(data);
          dashData.items.forEach((person) => {
            personArray.push(person);
          });
          setData(personArray);
        }
      });
    }
  }, [empresa]);
  const loadData = useCallback((filtro: string) => {
    setSearch(filtro);
  }, []);
  const organization = useCallback((org: string) => {
    const { connection, newMessage } = Connector();
    if (connection.state !== 'Disconnected') {
      newMessage(org);
    } else {
      connection.baseUrl = config.urlWebSocket + `/${org}`;
      connection.start().catch((err) => console.log(err));
    }
    setEmpresa(org);
  }, []);
  const providerValues = useMemo<IDataMapContext>(
    () => ({
      empresa,
      organization,
      loadData,
      search,
      handleRequestSort,
      orderDirection,
      orderBy,
      filteredPersons,
      selectedPerson,
      setSelectedPerson,
    }),
    [
      empresa,
      organization,
      loadData,
      search,
      handleRequestSort,
      orderDirection,
      orderBy,
      filteredPersons,
      selectedPerson,
      setSelectedPerson,
    ]
  );
  return <DataContext.Provider value={providerValues}>{children}</DataContext.Provider>;
};

export function useDataContext() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useDataContext not in DataContextProvider');
  }
  return context;
}

export default DataContextProvider;

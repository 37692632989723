import WatchOffIcon from '@mui/icons-material/WatchOff';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { ReactElement } from 'react';

import SignalAndBateryInformation from 'components/SignalAndBateryInformation';
import TableHeader from 'components/TableMeasurementRealTime/TableHeader';
import { EventTypes, Events } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { useDataContext } from 'context/DataMapContext';
import { useMarkerMapContext } from 'context/MarkerMapContext';
import { IPersonInfo } from 'interface/IPersonInfo';
import { DiffDateTable } from 'service/dateService';
import { selectMarkerOnMap } from 'utils/googleMapsUtils';
import styles from './styles.module.scss';

interface ITooltipEvents {
  children: ReactElement<any, any>;
  title: string;
}

function TooltipEvents({ title, children }: Readonly<ITooltipEvents>) {
  return (
    <Tooltip title={title} placement="top" followCursor>
      {children}
    </Tooltip>
  );
}

function returnStatusTooltips(person: IPersonInfo) {
  return (
    <>
      <SignalAndBateryInformation person={person} />
      {person.occurrencesTypes.includes(Events.WearingAlert) && (
        <TooltipEvents title="Não está usando o relógio">
          <WatchOffIcon sx={{ fill: 'black!important' }} />
        </TooltipEvents>
      )}
    </>
  );
}

function returnTableRowClassName(selectedPerson: IPersonInfo | null, currentPerson: IPersonInfo) {
  if (selectedPerson?.personId === currentPerson.personId) {
    return `${styles.personRow} ${styles.active}`;
  }
  return `${styles.personRow} 
    ${styles[currentPerson.currentActivityLevel]}`;
}

export default function TableMeasurementRealTime() {
  const {
    orderDirection,
    handleRequestSort,
    orderBy,
    filteredPersons,
    setSelectedPerson,
    selectedPerson,
  } = useDataContext();

  const { googleMapInstance, markers } = useMarkerMapContext();
  const { userInfo } = useAuthContext();
  function onClickTableRow(p: IPersonInfo) {
    setSelectedPerson(p);
    selectMarkerOnMap(googleMapInstance, markers, p);
  }

  return (
    <Box className={styles.fullHeightFixedBox}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="Tabela de pessoas em tempo real" size="small" stickyHeader>
          <TableHeader
            orderDirection={orderDirection}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredPersons.map((p) => (
              <TableRow
                key={p.personId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => {
                  onClickTableRow(p);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.preventDefault();
                  if (e.key === 'Enter' || e.key === ' ') {
                    onClickTableRow(p);
                  }
                }}
                className={returnTableRowClassName(selectedPerson, p)}
              >
                <TableCell align="left" component="th" scope="row">
                  {p.name}
                </TableCell>
                <TableCell align="center" scope="row">
                  {p.age}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {DiffDateTable(p.lastUpdate, userInfo.timeZone)}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {p.occurrencesTypes
                    .map((e) => {
                      return EventTypes[e as keyof typeof Events]?.name;
                    })
                    .join(', ')}
                </TableCell>
                <TableCell align="right" scope="row">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '0.25rem',
                    }}
                  >
                    {returnStatusTooltips(p)}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import api from 'api';

const refreshToken = async (email: string) => {
  const retorno = await api.get('/Timezone', { params: { email: email } });
  const accessToken = retorno.headers['x-access-token'];
  if (accessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  return retorno;
};

async function SignIn(username: string, password: string) {
  const response = api.post('/Authentication/Authenticate', undefined, {
    auth: {
      username,
      password,
    },
  });
  return response;
}

const authenticationService = {
  SignIn,
  refreshToken,
};

export default authenticationService;

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal } from '@mui/material';
import { ReactNode } from 'react';

import styles from './styles.module.scss';

export type ModalProps = {
  isOpen: boolean;
  onClose: (value: React.SetStateAction<boolean>) => void;
};

interface BaseModalProps extends ModalProps {
  title: string;
  children: ReactNode;
  showCloseButton?: boolean;
  showSaveButton?: boolean;
  saveButtonText?: string;
  onSaveCallback?: () => void;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  onSecondaryButtonCallback?: () => void;
  saveButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  fullscreen?: boolean;
  contentStyle?: React.CSSProperties;
}

export default function BaseModal({
  isOpen,
  onClose,
  title,
  children,
  showSaveButton = false,
  showSecondaryButton = false,
  onSaveCallback = () => {},
  onSecondaryButtonCallback = () => {},
  saveButtonDisabled = false,
  secondaryButtonDisabled = false,
  showCloseButton = true,
  saveButtonText = 'Salvar',
  secondaryButtonText = '',
  fullscreen = false,
  contentStyle,
}: Readonly<BaseModalProps>) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={`${styles.modalBackground} ${fullscreen ? styles.fullscreen : ''}`}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeading} id="modalHeaderDraggable">
            <h1>{title}</h1>
            {showCloseButton && (
              <IconButton onClick={() => onClose(false)} onTouchEnd={() => onClose(false)}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
          <div className={styles.modalContent} style={contentStyle}>
            {children}
          </div>
          <div className={styles.modalButtonRow}>
            {showSecondaryButton && (
              <div className={styles.modalActions}>
                <button
                  type="button"
                  onClick={() => onSecondaryButtonCallback()}
                  disabled={secondaryButtonDisabled}
                >
                  {secondaryButtonText}
                </button>
              </div>
            )}
            {showSaveButton && (
              <div className={styles.modalActions}>
                <button
                  type="button"
                  onClick={() => onSaveCallback()}
                  disabled={saveButtonDisabled}
                >
                  {saveButtonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import SignalWifi0BarTwoToneIcon from '@mui/icons-material/SignalWifi0BarTwoTone';
import SignalWifi1BarTwoToneIcon from '@mui/icons-material/SignalWifi1BarTwoTone';
import SignalWifi2BarTwoToneIcon from '@mui/icons-material/SignalWifi2BarTwoTone';
import SignalWifi3BarTwoToneIcon from '@mui/icons-material/SignalWifi3BarTwoTone';
import SignalWifi4BarTwoToneIcon from '@mui/icons-material/SignalWifi4BarTwoTone';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import SignalWifiStatusbarConnectedNoInternet4OutlinedIcon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4Outlined';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { useCurrentViewContext } from 'context/CurrentViewContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import { IPersonInfo } from 'interface/IPersonInfo';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo | null;
  direction?: 'row' | 'column';
}

function returnSizeIcon(currentMapView: MapViewEnum) {
  if (
    currentMapView === MapViewEnum.MENU_CARDS ||
    currentMapView === MapViewEnum.MENU_CARDS_DETAILS
  ) {
    return 'small';
  } else {
    return 'medium';
  }
}

function returnClassName(currentMapView: MapViewEnum) {
  if (
    currentMapView === MapViewEnum.MENU_CARDS ||
    currentMapView === MapViewEnum.MENU_CARDS_DETAILS
  ) {
    return styles.SignalAndBateryContainerInCards;
  } else {
    return styles.SignalAndBateryContainer;
  }
}

function returnDirectionClassName(direction?: 'row' | 'column') {
  switch (direction) {
    case 'column':
      return styles.columnDirection;
    case 'row':
      return styles.rowDirection;
    default:
      return styles.rowDirection;
  }
}

export default function SignalAndBateryInformation({ person, direction }: Readonly<IProps>) {
  const { currentMapView } = useCurrentViewContext();

  function returnIconSignal(percentage: number | null | undefined): React.ReactElement<any, any> {
    if (typeof percentage === 'number') {
      if (percentage === 100) {
        return (
          <SignalWifi4BarTwoToneIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 100 && percentage >= 75) {
        return (
          <SignalWifi3BarTwoToneIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 75 && percentage >= 50) {
        return (
          <SignalWifi2BarTwoToneIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 50 && percentage >= 25) {
        return (
          <SignalWifi1BarTwoToneIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 25 && percentage >= 1) {
        return (
          <SignalWifi0BarTwoToneIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      }
      return (
        <SignalWifiOffIcon
          fontSize={returnSizeIcon(currentMapView)}
          sx={{ fill: 'black!important' }}
        />
      );
    }
    return (
      <SignalWifiStatusbarConnectedNoInternet4OutlinedIcon
        fontSize={returnSizeIcon(currentMapView)}
        sx={{ fill: 'black!important' }}
      />
    );
  }

  function returnIconBattery(percentage: number | null | undefined): React.ReactElement<any, any> {
    if (typeof percentage === 'number') {
      if (percentage === 100) {
        return (
          <BatteryFullIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 100 && percentage >= 90) {
        return (
          <Battery90Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 90 && percentage >= 80) {
        return (
          <Battery80Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 80 && percentage >= 60) {
        return (
          <Battery60Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 80 && percentage >= 50) {
        return (
          <Battery50Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 50 && percentage >= 30) {
        return (
          <Battery30Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      } else if (percentage < 30 && percentage >= 20) {
        return (
          <Battery20Icon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        );
      }
      return (
        <Battery0BarIcon
          fontSize={returnSizeIcon(currentMapView)}
          sx={{ fill: 'black!important' }}
        />
      );
    }
    return (
      <BatteryUnknownIcon
        fontSize={returnSizeIcon(currentMapView)}
        sx={{ fill: 'black!important' }}
      />
    );
  }

  function returnBody(person: IPersonInfo | null) {
    if (person?.online === false) {
      return (
        <Tooltip title="Dispositivo Offline" placement="right">
          <SignalWifiOffIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important', opacity: 0.2 }}
          />
        </Tooltip>
      );
    }
    if (person?.devices && person?.devices.length >= 1) {
      return (
        <>
          <Tooltip title={`${person?.devices[0]?.signal}%`} placement="right">
            {returnIconSignal(person?.devices[0]?.signal)}
          </Tooltip>
          <Tooltip title={`${person?.devices[0]?.battery}%`} placement="right">
            {returnIconBattery(person?.devices[0]?.battery)}
          </Tooltip>
        </>
      );
    }
    return (
      <>
        <Tooltip title="Percentual do sinal não informado" placement="right">
          <SignalWifiStatusbarConnectedNoInternet4OutlinedIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        </Tooltip>
        <Tooltip title="Percentual da bateria não informado" placement="right">
          <BatteryUnknownIcon
            fontSize={returnSizeIcon(currentMapView)}
            sx={{ fill: 'black!important' }}
          />
        </Tooltip>
      </>
    );
  }

  return (
    <Box className={`${returnClassName(currentMapView)} ${returnDirectionClassName(direction)}`}>
      {returnBody(person)}
    </Box>
  );
}

import axios, { AxiosRequestConfig } from 'axios';
import config from 'config/config';

const axiosConfig: AxiosRequestConfig = {
  baseURL: config.apiUrlBase,
};

const api = axios.create(axiosConfig);

export default api;

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import BaseModal, { ModalProps } from 'components/BaseModal';
import { storageParams } from 'config/enum';
import { useAlertContext } from 'context/AlertContext';
import { useAuthContext } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import timezoneService from 'service/timezoneService';
import styles from './styles.module.scss';

interface ProfileModalProps extends ModalProps {}
interface AutocompleteOption {
  label: string;
  value: string;
}

export default function ProfileModal({ isOpen, onClose }: ProfileModalProps) {
  const { userInfo } = useAuthContext();
  const { notify } = useAlertContext();
  const [timeZone, setTimeZone] = useState<AutocompleteOption[]>([]);
  const [timeZoneSelect, setTimeZoneSelect] = useState<AutocompleteOption>({
    label: '',
    value: '',
  });

  useEffect(() => {
    const timezones: AutocompleteOption[] = [
      {
        label: '',
        value: '',
      },
    ];
    timezoneService.getTimeZone().then((res) => {
      res.forEach((x: any) => {
        timezones.push({ label: x.displayName, value: x.id });
      });
      setTimeZone(timezones);
      const timeSel = res.find((x: { id: string | undefined }) => x.id === userInfo.timeZone);
      setTimeZoneSelect({
        label: timeSel.displayName,
        value: timeSel.id,
      });
    });
  }, []);
  useEffect(() => {}, []);
  const handleSubmit = () => {
    const email = sessionStorage.getItem(storageParams.currentUserEmail);
    if (email && timeZoneSelect?.value) {
      timezoneService.postTimeZone(email, timeZoneSelect?.value).then(() => {
        notify('Timezone atualizado com sucesso', 'success', 'long');
        sessionStorage.setItem(storageParams.timeZone, timeZoneSelect?.value);
        window.location.reload();
        isOpen = false;
        onClose(false);
      });
    }
  };

  return (
    <BaseModal
      title="Perfil"
      isOpen={isOpen}
      onClose={onClose}
      showSaveButton
      saveButtonDisabled={timeZoneSelect.label.length > 0 ? false : true}
      onSaveCallback={handleSubmit}
    >
      <div className={styles.profileContainer}>
        <Autocomplete
          disablePortal
          id="select-chk"
          options={timeZone}
          sx={{ width: 300 }}
          value={timeZoneSelect}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          renderInput={(params) => <TextField {...params} label="TimeZone" />}
          onChange={(event, value) =>
            setTimeZoneSelect({
              label: value !== null ? value.label : '',
              value: value !== null ? value.value : '',
            })
          }
        />
      </div>
    </BaseModal>
  );
}

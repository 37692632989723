import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';

import Charts from 'components/BoxInformation/Charts';
import OccurrenceHistory from 'components/BoxInformation/OccurrenceHistory';
import PersonInformation from 'components/BoxInformation/PersonInformation';
import TableMeasurement from 'components/BoxInformation/TableMeasurement';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { useDataContext } from 'context/DataMapContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import { IPersonInfo } from 'interface/IPersonInfo';
import styles from './styles.module.scss';

export default function BoxInformation() {
  const { filteredPersons, selectedPerson } = useDataContext();
  const { currentMapView } = useCurrentViewContext();
  const [dataPerson, setDataPerson] = useState<IPersonInfo | null>(selectedPerson);
  const [measurementType, setMeasurementType] = useState<string>('');
  useEffect(() => {
    if (selectedPerson) {
      const personExist = filteredPersons.find((x) => x.personId === selectedPerson.personId);
      if (personExist) {
        setDataPerson(personExist);
      } else {
        setDataPerson(selectedPerson);
      }
    }
  }, [filteredPersons, selectedPerson]);

  useEffect(() => {
    if (selectedPerson?.personId !== dataPerson?.personId) {
      setMeasurementType('');
    }
  }, [selectedPerson, dataPerson]);

  return (
    <Box
      className={
        currentMapView === MapViewEnum.MENU_CARDS_DETAILS
          ? styles.boxInformationContainerCardDetail
          : styles.boxInformationContainer
      }
    >
      {selectedPerson ? (
        <>
          <PersonInformation person={dataPerson} />
          <Divider />
          <TableMeasurement
            person={dataPerson}
            measurementType={measurementType}
            setMeasurementType={setMeasurementType}
          />
          <OccurrenceHistory person={dataPerson} />
          <Charts
            measurementType={measurementType}
            person={dataPerson}
            selectedPerson={selectedPerson}
          />
        </>
      ) : (
        <div className={styles.noPersonSelected}>
          <p>Nenhuma pessoa foi selecionada.</p>
          <p>
            Para selecionar uma pessoa, clique em uma linha na tabela ao lado ou selecione uma
            pessoa no mapa.
          </p>
        </div>
      )}
    </Box>
  );
}

const Message = {
  title: 'PCA TakeCare Dash',
  defaultError: 'Desculpe, ocorreu um erro inesperado. Tente novamente mais tarde.',
  defaultSuccess: 'Operação realizada com sucesso.',
  serverTimeout:
    'Desculpe, não foi possível estabelecer conexão com o servidor. Tente novamente mais tarde.',
  oldRequestCanceled: 'OLD_REQUEST_CANCELED',
};

export default Message;

import { Box, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  getFirstAndLastDayOfMonth,
  getFirstAndLastDayOfWeek,
} from 'components/BoxInformation/Charts';
import styles from './styles.module.scss';

interface IProps {
  setDateInterval: Dispatch<SetStateAction<[Date, Date]>>;
  typeDate: string;
  setTypeDate: Dispatch<SetStateAction<string>>;
  chartDate: boolean;
  intervalDate: Dayjs;
  measurementType: string;
}

const datePickerStyles = {
  '& .MuiInputBase-root': {
    height: '35px',
    width: '170px',
    borderColor: '#024040',
  },
  '& .MuiInputAdornment-root .MuiIconButton-root': {
    color: '#024040 !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#024040 !important',
    fontSize: '13px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '13px',
  },
};

export default function FilterDate({
  setDateInterval,
  typeDate,
  setTypeDate,
  chartDate,
  intervalDate,
  measurementType,
}: Readonly<IProps>) {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  useEffect(() => {
    if (typeDate === 'mensal' && measurementType !== 'Steps') {
      setTypeDate('diario');
      setDateInterval([selectedDate.toDate(), selectedDate.toDate()]);
    } else {
      if (typeDate === 'diario' && !chartDate) {
        setDateInterval([selectedDate.toDate(), selectedDate.toDate()]);
      } else if (typeDate === 'semanal') {
        setDateInterval(getFirstAndLastDayOfWeek(selectedDate.toDate()));
      } else if (typeDate === 'mensal' && measurementType === 'Steps') {
        setDateInterval(getFirstAndLastDayOfMonth(selectedDate.toDate()));
      } else if (typeDate === 'diario' && chartDate) {
        setDateInterval([selectedDate.toDate(), selectedDate.toDate()]);
        setSelectedDate(intervalDate);
      }
    }
  }, [selectedDate, typeDate, measurementType]);
  return (
    <Box className={styles.filterDateContainer}>
      <Box className={styles.dateType}>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            height: '35px',
          }}
        >
          <ToggleButtonGroup
            value={typeDate}
            exclusive
            onChange={(_, value) => {
              if (value !== null) {
                setTypeDate(value);
              }
            }}
            aria-label="Selecione diário ou semanal"
            size="small"
            sx={{
              borderColor: '#024040 !important',
              height: '35px',
              '& .Mui-selected': {
                backgroundColor: '#036666!important',
                color: 'white!important',
              },
            }}
          >
            <ToggleButton
              value="diario"
              sx={{
                color: '#024040 !important',
                borderColor: '#024040 !important',
              }}
            >
              D
            </ToggleButton>
            <ToggleButton
              value="semanal"
              sx={{
                color: '#024040 !important',
                borderColor: '#024040 !important',
              }}
            >
              S
            </ToggleButton>
            {measurementType === 'Steps' && (
              <ToggleButton
                value="mensal"
                sx={{
                  color: '#024040 !important',
                  borderColor: '#024040 !important',
                }}
              >
                M
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Paper>
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="DD/MM/AAAA"
            format="DD/MM/YYYY"
            closeOnSelect={true}
            onChange={(e: any) => setSelectedDate(e)}
            value={selectedDate}
            sx={datePickerStyles}
            slotProps={{
              layout: {
                sx: {
                  '.MuiPickersYear-root': {
                    textAlign: 'center',
                  },
                  '.MuiDateCalendar-root .MuiDateCalendar-viewTransitionContainer .Mui-selected': {
                    backgroundColor: '#036666',
                    color: 'white',
                  },
                  '& .MuiDayCalendar-weekContainer:has(> .Mui-selected)': {
                    '.MuiPickersDay-root': {
                      backgroundColor: typeDate === 'semanal' ? '#036666' : '',
                      color: typeDate === 'semanal' ? 'white' : '',
                    },
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}

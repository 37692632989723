import { Controller, ControllerFieldState, ControllerRenderProps, useForm } from 'react-hook-form';

import BaseModal, { ModalProps } from 'components/BaseModal';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@mui/material';
import { useAlertContext } from 'context/AlertContext';
import userService from 'service/userService';
import { UpdateUserFormSchema, UserUpdatePassword } from './helpers';
import styles from './styles.module.scss';

interface PasswordModalProps extends ModalProps {}

interface FieldAndFieldStateType {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
}

export default function PasswordModal({ isOpen, onClose }: PasswordModalProps) {
  const { notify } = useAlertContext();
  const { handleSubmit, control, reset } = useForm<UserUpdatePassword>({
    resolver: yupResolver(UpdateUserFormSchema),
  });

  async function onCreateUserHandler(data: UserUpdatePassword) {
    try {
      await userService.updatePassword(data);
      notify('A sua senha foi redefinida com sucesso!', 'success', 'long');
    } catch (e: any) {
      notify('Houve um erro ao redefinir a sua senha!', 'error', 'long');
    } finally {
      reset();
      onClose(true);
    }
  }
  const redefinirSenhaInput = ({ field, fieldState }: FieldAndFieldStateType) => (
    <TextField type="password" label="Senha Atual" error={!!fieldState.error} {...field} />
  );

  const senhaInput = ({ field, fieldState }: FieldAndFieldStateType) => (
    <TextField type="password" label="Nova Senha" error={!!fieldState.error} {...field} />
  );

  const confirmarNovaSenhaInput = ({ field, fieldState }: FieldAndFieldStateType) => (
    <TextField type="password" label="Confirmar Nova Senha" error={!!fieldState.error} {...field} />
  );
  return (
    <BaseModal
      title="Redefinir senha"
      isOpen={isOpen}
      onClose={onClose}
      showSaveButton
      onSaveCallback={handleSubmit(onCreateUserHandler)}
    >
      <div className={styles.formContainer}>
        <fieldset className={styles.inputContainer}>
          <Controller name="currentPassword" control={control} render={redefinirSenhaInput} />
        </fieldset>
        <fieldset className={styles.inputContainer}>
          <Controller name="newPassword" control={control} render={senhaInput} />
        </fieldset>
        <fieldset className={styles.inputContainer}>
          <Controller
            name="confirmNewPassword"
            control={control}
            render={confirmarNovaSenhaInput}
          />
        </fieldset>
      </div>
    </BaseModal>
  );
}

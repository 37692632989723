import { useEffect, useState } from 'react';

interface WindowProperties {
  width: number;
  height: number;
  isMobile: boolean;
}

export default function useWindow() {
  const [state, setState] = useState<WindowProperties>({
    height: window.innerHeight,
    width: window.innerWidth,
    isMobile: window.innerWidth <= 1020,
  });

  function changeWindowProperties() {
    setState({
      height: window.innerHeight,
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1020,
    });
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowProperties);
    return () => window.removeEventListener('resize', changeWindowProperties);
  }, []);

  return state;
}

import { Drawer, styled } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import TableMeasurementRealTime from 'components/TableMeasurementRealTime';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import styles from './styles.module.scss';

interface IProps {
  _tableMeasurement: boolean;
  setTableMeasurement: Dispatch<SetStateAction<boolean>>;
}

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paperAnchorDockedBottom {
    border: none;
  }
`;

function returnTableClassName(currentMapView: MapViewEnum) {
  if (currentMapView === MapViewEnum.FIXED_DETAILS_MENU) {
    return styles.drawerContainerFixed;
  }
  if (currentMapView === MapViewEnum.OPEN_CLOSE_DETAILS_MENU) {
    return styles.drawerContainer;
  }
  if (
    currentMapView === MapViewEnum.MAP_ONLY ||
    currentMapView === MapViewEnum.MENU_CARDS ||
    currentMapView === MapViewEnum.MENU_CARDS_DETAILS
  ) {
    return styles.drawerContainerOff;
  }
  return styles.drawerContainerFullHeight;
}

function returnTableAnchor(currentMapView: MapViewEnum, beforeMapView: MapViewEnum | null) {
  switch (currentMapView) {
    case MapViewEnum.FIXED_DETAILS_MENU:
      return 'bottom';
    case MapViewEnum.MAP_ONLY:
      if (beforeMapView === MapViewEnum.OPEN_CLOSE_DETAILS_MENU) {
        return 'bottom';
      }
      if (beforeMapView === MapViewEnum.FIXED_DETAILS_MENU) {
        return 'right';
      }
      return 'bottom';
    case MapViewEnum.OPEN_CLOSE_DETAILS_MENU:
      return 'bottom';
    case MapViewEnum.MENU_CARDS:
      return 'bottom';
  }
}

export default function MenuTableMeasurementRealTime({
  _tableMeasurement,
  setTableMeasurement,
}: Readonly<IProps>) {
  const { currentMapView, beforeMapView } = useCurrentViewContext();

  return (
    <StyledDrawer
      anchor={returnTableAnchor(currentMapView, beforeMapView)}
      open={_tableMeasurement}
      onClose={() => setTableMeasurement(false)}
      variant="persistent"
      className={returnTableClassName(currentMapView)}
    >
      <TableMeasurementRealTime />
    </StyledDrawer>
  );
}

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { MapViewEnum } from 'enums/MapViewEnum';
import { storageParams } from 'config/enum';
import useWindow from 'hooks/useWindow';

interface CurrentViewContextReturnType {
  currentMapView: MapViewEnum;
  changeCurrentMapView: (mapView: MapViewEnum) => void;
  beforeMapView: MapViewEnum | null;
}

interface CurrentViewContextProps {
  children: React.ReactNode;
}

function getCurrentViewTypeStorage() {
  const currentMapView = localStorage.getItem(storageParams.viewType);

  if (currentMapView) {
    return MapViewEnum[currentMapView as keyof typeof MapViewEnum];
  }
  return MapViewEnum.OPEN_CLOSE_DETAILS_MENU;
}

const CurrentViewContext = createContext({} as CurrentViewContextReturnType);

export default function CurrentViewContextProvider({
  children,
}: Readonly<CurrentViewContextProps>) {
  const [currentMapView, setCurrentMapView] = useState<MapViewEnum>(getCurrentViewTypeStorage());
  const [beforeMapView, setBeforeMapView] = useState<MapViewEnum | null>(null);
  const windowSize = useWindow();

  const changeCurrentMapView = useCallback(
    function (mapView: MapViewEnum) {
      setBeforeMapView(currentMapView);
      setCurrentMapView(mapView);
    },
    [currentMapView]
  );

  useEffect(() => {
    localStorage.setItem(storageParams.viewType, MapViewEnum[currentMapView]);
  }, [currentMapView]);

  useEffect(() => {
    if (windowSize.isMobile) {
      setCurrentMapView(MapViewEnum.MENU_CARDS);
    }
  }, [windowSize.isMobile]);

  const providerValues = useMemo<CurrentViewContextReturnType>(
    () => ({
      currentMapView,
      beforeMapView,
      changeCurrentMapView,
    }),
    [currentMapView, beforeMapView, changeCurrentMapView]
  );

  return (
    <CurrentViewContext.Provider value={providerValues}>{children}</CurrentViewContext.Provider>
  );
}

export function useCurrentViewContext() {
  const context = useContext(CurrentViewContext);
  if (context === undefined) {
    throw new Error('CurrentViewContext not in CurrentViewContextProvider');
  }
  return context;
}

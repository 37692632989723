import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'api';
import { buildValidationErros } from 'api/utils';
import { httpStatusCode, storageParams } from 'config/enum';
import Message from 'config/message';
import { useAlertContext } from 'context/AlertContext';

interface IWithapiProps {
  children: React.ReactNode;
}

let apiInterceptorResponse: number | null = null;

// Possibilita utilizar os React Hooks no tratamento de exceções.
const Withapi: React.FunctionComponent<IWithapiProps> = function ({ children }) {
  const navigate = useNavigate();
  const { notify } = useAlertContext();

  useEffect(() => {
    if (!!apiInterceptorResponse || apiInterceptorResponse === 0) {
      api.interceptors.response.eject(apiInterceptorResponse);
    }

    apiInterceptorResponse = api.interceptors.response.use(
      function (response) {
        // hideLoading();
        // Atualiza Token JWT
        const tokenJwt = response.headers['x-access-token'];
        const csrfTkn = response.headers['x-csrf-token'];
        if (tokenJwt) {
          api.defaults.headers['Authorization'] = `Bearer ${tokenJwt}`;
          sessionStorage.setItem(storageParams.tokenJwt, tokenJwt); // Salva token JWT no session storage
        }

        if (csrfTkn) {
          api.defaults.headers.get['X-Csrf-Token'] = csrfTkn;
          api.defaults.headers.post['X-Csrf-Token'] = csrfTkn;
          api.defaults.headers.put['X-Csrf-Token'] = csrfTkn;
          api.defaults.headers.delete['X-Csrf-Token'] = csrfTkn;
          api.defaults.headers.patch['X-Csrf-Token'] = csrfTkn;
        }

        if (response.data.message) {
          notify(response.data.message, response.data.status || 'success', 'long');
        }

        return Promise.resolve(response);
      },
      function (error) {
        if (error.message === Message.oldRequestCanceled) {
          return Promise.reject(error);
        }
        // hideLoading();
        if (error.message === 'isOffline') {
          return Promise.reject(error);
        }

        // let message = Message.defaultError;
        let message = '';
        let status: 'error' | 'info' | 'warning' | 'success' = 'error';

        if (error.code === 'ECONNABORTED') {
          message = Message.serverTimeout;
        }

        if (error.response) {
          const statusCode = error.response.status;

          switch (true) {
            case statusCode === httpStatusCode.Unauthorized:
              if (sessionStorage.getItem(storageParams.tokenJwt)) {
                notify(
                  'A sua sessão expirou! Por favor, faça o login novamente!',
                  'warning',
                  'long'
                );
                sessionStorage.removeItem(storageParams.tokenJwt);
                api.defaults.headers.common['Authorization'] = '';
                navigate('/login');
              } else {
                notify('Usuário ou senha inválidos!', 'error', 'long');
              }
              break;

            case statusCode === httpStatusCode.BusinessException:
              status = 'warning';
              break;

            case statusCode >= 500:
              notify(
                'Houve um erro inesperado! Por favor, tente novamente mais tarde!',
                'error',
                'long'
              );
              break;
          }

          // Recupera mensagem enviada pela API
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
          }

          if (['info', 'warning', 'success', 'error'].includes(error.response.data.status)) {
            status = error.response.data.status;
          }

          // Erros de validação
          if (error.response.data.errors) {
            message = buildValidationErros(error.response.data.errors);
            status = 'error';
          }

          if (message) {
            notify(message, status, 'long');
          }

          return Promise.reject(error);
        }

        if (message) {
          notify(message, status, 'long');
        }

        return Promise.reject(error);
      }
    );
  }, [navigate, notify]);

  return <>{children}</>;
};

export default Withapi;

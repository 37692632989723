import { Drawer } from '@mui/material';
import BoxInformation from 'components/BoxInformation';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { useDataContext } from 'context/DataMapContext';
import { useDrawerBoxInformationContext } from 'context/DrawerBoxInformationContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import styles from './styles.module.scss';

export default function MenuBoxInformation() {
  const { currentMapView } = useCurrentViewContext();
  const { setSelectedPerson } = useDataContext();
  const { openDrawer, setOpenDrawer } = useDrawerBoxInformationContext();

  function closeDrawerOperation() {
    setOpenDrawer(false);
    setSelectedPerson(null);
  }

  function openDrawerOperation(currentMapView: MapViewEnum) {
    if (
      currentMapView === MapViewEnum.FIXED_DETAILS_MENU ||
      currentMapView === MapViewEnum.OPEN_CLOSE_DETAILS_MENU
    ) {
      return true;
    } else if (currentMapView === MapViewEnum.MENU_CARDS_DETAILS) {
      return openDrawer;
    } else {
      return false;
    }
  }

  return (
    <Drawer
      anchor="left"
      open={openDrawerOperation(currentMapView)}
      onClose={() => closeDrawerOperation()}
      variant={currentMapView === MapViewEnum.MENU_CARDS_DETAILS ? 'temporary' : 'persistent'}
      className={
        currentMapView === MapViewEnum.MENU_CARDS_DETAILS
          ? styles.drawerContainerCardDetail
          : styles.drawerContainer
      }
    >
      <BoxInformation />
    </Drawer>
  );
}

import * as yup from 'yup';

export const UpdateUserFormSchema = yup.object({
  currentPassword: yup.string().required('O campo de senha atual é obrigatório'),
  newPassword: yup.string().required('O campo de nova senha é obrigatório'),
  confirmNewPassword: yup.string().required('O campo de confirmar nova senha é obrigatório'),
});

export interface UserUpdatePassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

import api from 'api';
import { IMeasurementHistory, IMeasurementTypes } from 'interface/IMeasurement';

const measurementCreate = async function (idPerson: string, data: IMeasurementTypes) {
  return api.post(`/Persons/${idPerson}/MeasurementRequests`, data).catch((error) => {
    console.log(error);
  });
};

const measurementHistory = async function (
  personId: string,
  measurementDateStart: string,
  measurementDateEnd: string,
  measurementType: string
): Promise<IMeasurementHistory[]> {
  const resp = await api.get(
    `/Persons/${personId}/MeasurementHistory/${measurementDateStart}/${measurementDateEnd}/${measurementType}`
  );
  return resp.data;
};

const measurementService = {
  measurementCreate,
  measurementHistory,
};

export default measurementService;

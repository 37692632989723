import { PersonCriticality, PersonEvent } from 'interface/IPersonInfo';

const measurement = {
  heartRate: 'HeartRate',
  bloodPressure: 'BloodPressure',
  oximetry: 'Oximetry',
  bloodSugar: 'BloodSugar',
  bodyTemperature: 'BodyTemperature',
  steps: 'Steps',
};

const httpStatusCode = {
  Continue: 100,
  SwitchingProtocols: 101,
  OK: 200,
  Created: 201,
  Accepted: 202,
  NonAuthoritativeInformation: 203,
  NoContent: 204,
  ResetContent: 205,
  PartialContent: 206,
  MultipleChoices: 300,
  Ambiguous: 300,
  MovedPermanently: 301,
  Moved: 301,
  Found: 302,
  Redirect: 302,
  SeeOther: 303,
  RedirectMethod: 303,
  NotModified: 304,
  UseProxy: 305,
  Unused: 306,
  TemporaryRedirect: 307,
  RedirectKeepVerb: 307,
  BadRequest: 400,
  Unauthorized: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  ProxyAuthenticationRequired: 407,
  RequestTimeout: 408,
  Conflict: 409,
  Gone: 410,
  LengthRequired: 411,
  PreconditionFailed: 412,
  RequestEntityTooLarge: 413,
  RequestUriTooLong: 414,
  UnsupportedMediaType: 415,
  RequestedRangeNotSatisfiable: 416,
  ExpectationFailed: 417,
  BusinessException: 422,
  UpgradeRequired: 426,
  InternalServerError: 500,
  NotImplemented: 501,
  BadGateway: 502,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  HttpVersionNotSupported: 505,
};

const storageParams = {
  email: 'email',
  tokenJwt: 'tokenJwt',
  name: 'nome',
  tokenStep2FA: 'token2FA',
  tokenConfianca: 'tokenConfianca',
  trustDevice: 'trustDevice',
  userType: 'userType',
  userId: 'userId',
  viewType: 'viewType',
  savedUsername: 'savedUsername',
  currentUserEmail: 'currentUserEmail',
  viewedMessages: 'viewedMessages',
  systemConfigs: 'systemConfigs',
  timeZone: 'timeZone',
  organization: 'organization',
};

const messagesModal = {
  downloadApp: 'downloadApp',
};

const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export enum Events {
  'None' = 'None',
  'Unknown' = 'Unknown',
  'Sos' = 'Sos',
  'LowBattery' = 'LowBattery',
  'Fall' = 'Fall',
  'FallCanceled' = 'FallCanceled',
  'WearingAlert' = 'WearingAlert',
  'HeartBeat' = 'HeartBeat',
  'OnLine' = 'OnLine',
  'OffLine' = 'OffLine',
}

export enum Criticality {
  'Critical' = 'Critical',
  'Warning' = 'Warning',
  'Information' = 'Information',
}

export const CriticalityTypes: Record<Criticality, PersonCriticality> = {
  Critical: { name: 'Crítico', value: 200 },
  Warning: { name: 'Alerta', value: 100 },
  Information: { name: 'Info', value: 0 },
};

const EventTypes: Record<Events, PersonEvent> = {
  Sos: {
    name: 'Sos',
    criticality: CriticalityTypes.Critical,
  },
  Fall: {
    name: 'Queda',
    criticality: CriticalityTypes.Critical,
  },
  WearingAlert: {
    name: 'Alerta de Uso',
    criticality: CriticalityTypes.Warning,
  },
  LowBattery: {
    name: 'Bateria Baixa',
    criticality: CriticalityTypes.Warning,
  },
  FallCanceled: {
    name: 'Queda Cancelada',
    criticality: CriticalityTypes.Warning,
  },
  HeartBeat: {
    name: 'Frequência Cardíaca',
    criticality: CriticalityTypes.Information,
  },
  OnLine: {
    name: 'Online',
    criticality: CriticalityTypes.Information,
  },
  OffLine: {
    name: 'Offline',
    criticality: CriticalityTypes.Information,
  },
  None: {
    name: '',
    criticality: CriticalityTypes.Information,
  },
  Unknown: {
    name: 'Desconhecido',
    criticality: CriticalityTypes.Information,
  },
};

export { EventTypes, httpStatusCode, measurement, messagesModal, monthNames, storageParams };

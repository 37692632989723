import { Avatar, Badge, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import EmergencyIcon from 'assets/map_icon_emergency.png';
import SignalAndBateryInformation from 'components/SignalAndBateryInformation';
import { Events } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { IPersonInfo } from 'interface/IPersonInfo';
import { LastUpdate } from 'service/dateService';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo | null;
}

const StyledSuccessBadge = styled(Badge)(({ theme }) => ({
  '& .MuiAvatar-root': {
    width: '32px',
    height: '32px',
  },
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const StyledErrorBadge = styled(Badge)(({ theme }) => ({
  '& .MuiAvatar-root': {
    width: '32px',
    height: '32px',
  },
  '& .MuiBadge-badge': {
    backgroundColor: '#ed0000',
    color: '#ed0000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const StyledSosBadge = styled(Badge)(() => ({
  '& .MuiAvatar-root': {
    width: '32px',
    height: '32px',
  },
  '& .MuiAvatar-img': {
    width: '16px',
    height: '16px',
  },
}));

export default function CardHeader({ person }: Readonly<IProps>) {
  const { userInfo } = useAuthContext();
  function returnBadgeByDeviceStatus() {
    if (person?.online === true) {
      return (
        <StyledSuccessBadge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
        >
          <Avatar src={person?.urlPhoto} alt={person?.name}></Avatar>
        </StyledSuccessBadge>
      );
    }
    return (
      <StyledErrorBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar src={person?.urlPhoto} alt={person?.name}></Avatar>
      </StyledErrorBadge>
    );
  }

  return (
    <Box className={styles.personInformationDivHeader}>
      <Box className={styles.fotoIdosoDiv}>
        {person &&
          (person.occurrencesTypes.includes(Events.Sos) ||
          person.occurrencesTypes.includes(Events.Fall) ? (
              <StyledSosBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={<Avatar alt="Sos" src={EmergencyIcon} />}
              >
                <Avatar src={person?.urlPhoto} alt={person?.name}></Avatar>
              </StyledSosBadge>
            ) : (
              returnBadgeByDeviceStatus()
            ))}
      </Box>
      <Box className={styles.personInformationDiv}>
        <Typography variant="h6" className={styles.personName}>
          {person?.name}
        </Typography>
        <Typography variant="h6" className={styles.personAge}>
          {person?.age} anos
        </Typography>
        <Typography variant="h6" className={styles.cardLastSeen}>
          {LastUpdate(person, userInfo.timeZone)}
        </Typography>
      </Box>
      <SignalAndBateryInformation person={person} />
    </Box>
  );
}

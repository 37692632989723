import { IPersonInfo } from 'interface/IPersonInfo';

function formatDateToHoursAndMinutes(date: string | Date, timezone: string | undefined) {
  const data = new Date(date).toLocaleString('pt-BR', {
    timeZone: timezone ?? 'America/Sao_Paulo',
    hour: '2-digit',
    minute: '2-digit',
  });
  return data ? data.toString() : '-';
}

function formatDateToDateTime(date: string | Date, timezone: string | undefined) {
  const data = new Date(date).toLocaleString('pt-BR', {
    timeZone: timezone ?? 'America/Sao_Paulo',
    hour: '2-digit',
    minute: '2-digit',
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  return data ? data.toString().replace(',', ' às ') : '-';
}

function returnDate(date: string) {
  return new Date(date);
}

function formatTimestampForDate(timestamp: number, typeDate: string, timezone: string | undefined) {
  const dataFormat = new Date(timestamp * 1000).toLocaleString('pt-BR', {
    timeZone: timezone ?? 'America/Sao_Paulo',
  });

  const data = dataFormat?.split(',');

  if (typeDate !== 'diario') {
    return `${data[0]}`;
  } else {
    return `${data[1]}`;
  }
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function formatDate2Digits(date: Date, timezone: string | undefined) {
  const data = new Date(date).toLocaleString('pt-BR', {
    timeZone: timezone ?? 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const dataAtt = new Date(data);
  return [
    dataAtt.getFullYear(),
    padTo2Digits(dataAtt.getMonth() + 1),
    padTo2Digits(dataAtt.getDate()),
  ].join('-');
}
function formatDateRequest(date: Date) {
  const retorno = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
  return retorno;
}

function LastUpdate(person: IPersonInfo | null, timezone: string | undefined) {
  if (person !== null) {
    return DiffDate(person.lastUpdate, timezone);
  }
}

function DiffDate(date: string | Date, timezone: string | undefined) {
  let retorno = '';
  if (date) {
    const attDate = new Date();
    const lastDate = new Date(date);
    const hour = formatDateToHoursAndMinutes(date, timezone);
    const dateTime = formatDateToDateTime(date, timezone);
    const time = attDate.getTime() - lastDate.getTime();
    const days = time / (1000 * 3600 * 24);

    if (formatDate2Digits(attDate, timezone) === formatDate2Digits(lastDate, timezone)) {
      retorno = `Visto hoje às ${hour}`;
    } else if (days < 2) {
      retorno = `Visto ontem às ${hour}`;
    } else if (days >= 2) {
      retorno = `Visto em ${dateTime}`;
    }
  }

  return retorno;
}

function DiffDateTable(date: string | Date, timezone: string | undefined) {
  let retorno = '';
  if (date) {
    const attDate = new Date();
    const lastDate = new Date(date);
    const hour = formatDateToHoursAndMinutes(date, timezone);
    const dateTime = formatDateToDateTime(date, timezone);
    const time = attDate.getTime() - lastDate.getTime();
    const days = time / (1000 * 3600 * 24);

    if (formatDate2Digits(attDate, timezone) === formatDate2Digits(lastDate, timezone)) {
      retorno = `Hoje às ${hour}`;
    } else if (days < 2) {
      retorno = `Ontem às ${hour}`;
    } else if (days >= 2) {
      retorno = `${dateTime}`;
    }
  }

  return retorno;
}

export {
  DiffDate,
  DiffDateTable,
  LastUpdate,
  formatDate2Digits,
  formatDateRequest,
  formatDateToDateTime,
  formatDateToHoursAndMinutes,
  formatTimestampForDate,
  returnDate,
};

import { Box } from '@mui/material';

import CardPerson from 'components/CardsMeasurement/CardPerson';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import { useDataContext } from 'context/DataMapContext';
import styles from './styles.module.scss';

export default function BoxCardsMeasurement() {
  const { filteredPersons } = useDataContext();
  const { currentMapView } = useCurrentViewContext();

  return currentMapView === MapViewEnum.MENU_CARDS ||
    currentMapView === MapViewEnum.MENU_CARDS_DETAILS ? (
      <Box className={styles.BoxCards}>
        <div className={styles.body}>
          {filteredPersons.map((p) => (
            <CardPerson person={p} key={p.personId} />
          ))}
        </div>
      </Box>
    ) : (
      <></>
    );
}

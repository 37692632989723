import { Box, Divider } from '@mui/material';
import { memo } from 'react';

import CardBody from 'components/CardsMeasurement/CardPerson/CardBody';
import CardFooter from 'components/CardsMeasurement/CardPerson/CardFooter';
import CardHeader from 'components/CardsMeasurement/CardPerson/CardHeader';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { useDataContext } from 'context/DataMapContext';
import { useDrawerBoxInformationContext } from 'context/DrawerBoxInformationContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import { IPersonInfo } from 'interface/IPersonInfo';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo;
}

const CardPerson = memo(({ person }: Readonly<IProps>) => {
  const { changeCurrentMapView, currentMapView } = useCurrentViewContext();
  const { setOpenDrawer } = useDrawerBoxInformationContext();
  const { setSelectedPerson } = useDataContext();

  function onClickViewDetailsPerson(p: IPersonInfo, currentMapView: MapViewEnum) {
    if (
      currentMapView === MapViewEnum.MENU_CARDS ||
      currentMapView === MapViewEnum.MENU_CARDS_DETAILS
    ) {
      setSelectedPerson(p);
      setOpenDrawer(true);
      changeCurrentMapView(MapViewEnum.MENU_CARDS_DETAILS);
    } else {
      setSelectedPerson(null);
      setOpenDrawer(false);
      changeCurrentMapView(MapViewEnum.MENU_CARDS);
    }
  }

  return (
    <Box
      className={styles.BoxCards}
      onClick={() => onClickViewDetailsPerson(person, currentMapView)}
    >
      <CardHeader person={person} />
      <CardBody person={person} />
      <div style={{ margin: '1rem' }}>
        <Divider />
        <CardFooter person={person} />
      </div>
    </Box>
  );
});
CardPerson.displayName = 'CardPerson';

export default CardPerson;

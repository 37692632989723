import { useState } from 'react';

type UseModalReturnResult = [
  boolean,
  (value: React.SetStateAction<boolean>) => void,
  (value: React.SetStateAction<boolean>) => void,
];

export default function useModal(): UseModalReturnResult {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return [isModalOpen, handleOpenModal, handleCloseModal];
}

import { useCallback, useEffect, useState } from 'react';

import { InfoRounded } from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import ReportIcon from '@mui/icons-material/Report';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CriticalityTypes, EventTypes, Events } from 'config/enum';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { useMarkerMapContext } from 'context/MarkerMapContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import { IPersonInfo, PersonEvent } from 'interface/IPersonInfo';
import { selectMarkerOnMap } from 'utils/googleMapsUtils';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo;
}

interface QtdeEventAndOccurrences {
  critical: number;
  warning: number;
  info: number;
}

export default function CardFooter({ person }: Readonly<IProps>) {
  const [qtdeOccurrence, setQtdeOccurrence] = useState<QtdeEventAndOccurrences>({
    critical: 0,
    warning: 0,
    info: 0,
  });

  const { markers, googleMapInstance } = useMarkerMapContext();
  const { changeCurrentMapView } = useCurrentViewContext();

  function onClickViewMapPerson(
    p: IPersonInfo,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    changeCurrentMapView(MapViewEnum.MAP_ONLY);
    selectMarkerOnMap(googleMapInstance, markers, p);
  }

  const updateOccurrenceCount = useCallback((events: string[]) => {
    const occurrenceQty = {
      critical: 0,
      warning: 0,
      info: 0,
    };

    events.forEach((p) => {
      const event: PersonEvent | null = EventTypes[p as keyof typeof Events];

      switch (event?.criticality) {
        case CriticalityTypes.Critical:
          occurrenceQty.critical += 1;
          break;

        case CriticalityTypes.Warning:
          occurrenceQty.warning += 1;
          break;

        case CriticalityTypes.Information:
          occurrenceQty.info += 1;
          break;
      }
    });

    setQtdeOccurrence(occurrenceQty);
  }, []);

  useEffect(() => {
    const occurrence = person.occurrencesTypes;
    updateOccurrenceCount([...occurrence]);
  }, [person.eventType, person.occurrencesTypes, updateOccurrenceCount]);

  return (
    <Box className={styles.CardFooter}>
      <Tooltip title="Ver no mapa">
        <div className={styles.occurrences}>
          <IconButton
            size="small"
            aria-label="Ver no mapa"
            onClick={(event) => onClickViewMapPerson(person, event)}
          >
            <MapIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>

      <div className={styles.boxOccurrences}>
        {qtdeOccurrence.critical > 0 && (
          <Tooltip title="Ocorrências">
            <div className={styles.occurrences}>
              <IconButton size="small">
                <ReportIcon color="error" fontSize="small" />
                <Typography variant="subtitle1">{qtdeOccurrence.critical}</Typography>
              </IconButton>
            </div>
          </Tooltip>
        )}
        {qtdeOccurrence.warning > 0 && (
          <Tooltip title="Ocorrências">
            <div className={styles.occurrences}>
              <IconButton size="small">
                <ReportProblemIcon color="warning" fontSize="small" />
                <Typography variant="subtitle1">{qtdeOccurrence.warning}</Typography>
              </IconButton>
            </div>
          </Tooltip>
        )}
        {qtdeOccurrence.info > 0 && (
          <Tooltip title="Informações">
            <div className={styles.occurrences}>
              <IconButton size="small">
                <InfoRounded color="info" fontSize="small" />
                <Typography variant="subtitle1">{qtdeOccurrence.info}</Typography>
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </Box>
  );
}

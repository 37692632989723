import { Button, Divider, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';

import { Criticality, CriticalityTypes, EventTypes, Events } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { IOccurrence } from 'interface/IOccurrence';
import { IPersonInfo } from 'interface/IPersonInfo';
import { formatDateToHoursAndMinutes } from 'service/dateService';
import occurrenceService from 'service/occurrenceService';
import styles from './styles.module.scss';

interface OccurrenceHistoryProps {
  person: IPersonInfo | null;
}

export default function OccurrenceHistory({ person }: Readonly<OccurrenceHistoryProps>) {
  const [ocurrencies, setOcurrencies] = useState<IOccurrence[]>([]);
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const { userInfo } = useAuthContext();
  useEffect(() => {
    async function fetchOccurrence() {
      try {
        const response = await occurrenceService.getOccurrence(person!.personId);
        setOcurrencies(response);
      } catch (error) {
        console.log('Error when loading ocurrencies');
        setOcurrencies([]);
      }
    }

    if (person) {
      fetchOccurrence();
    }
  }, [person, refreshKey]);

  function returnOcurrenciesBody() {
    if (ocurrencies.length > 0) {
      return (
        <>
          <Divider />
          <p className={styles.title}>Detalhes das Ocorrências</p>
          <Table size="small" className={styles.table}>
            <TableBody>
              {ocurrencies.map((oc) => (
                <TableRow key={oc.id} className={styles.tableRow}>
                  <TableCell>
                    {formatDateToHoursAndMinutes(oc.creationDate, userInfo.timeZone)}
                  </TableCell>
                  <TableCell
                    sx={{ width: '100%', maxWidth: '100px' }}
                    className={styles.overflowTextCell}
                    title={EventTypes[oc.type as keyof typeof Events]?.name}
                  >
                    {EventTypes[oc.type as keyof typeof Events]?.name}
                  </TableCell>
                  <TableCell
                    sx={{ maxWidth: '80px' }}
                    className={styles.overflowTextCell}
                    title={CriticalityTypes[oc.critical as keyof typeof Criticality]?.name}
                  >
                    {CriticalityTypes[oc.critical as keyof typeof Criticality]?.name}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className={styles.dismissButton}
                      size="small"
                      onClick={() => {
                        async function dismissOccurrence() {
                          try {
                            await occurrenceService.dismissOccurrence(person?.personId, oc.type);
                            setRefreshKey((old) => old + 1);
                          } catch (error) {
                            console.log('Error when trying to dismiss an occurrence');
                          }
                        }
                        dismissOccurrence();
                      }}
                    >
                      Baixar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      );
    }
    return null;
  }

  return <div className={styles.occurrenceHistoryContainer}>{returnOcurrenciesBody()}</div>;
}

import WithAxios from 'api/interceptor';
import AlertContextProvider from 'context/AlertContext';
import AuthContextProvider from 'context/AuthContext';
import CurrentViewContextProvider from 'context/CurrentViewContext';
import DataContextProvider from 'context/DataMapContext';
import MakerMapContextProvider from 'context/MarkerMapContext';
import DrawerBoxInformationContextProvider from 'context/DrawerBoxInformationContext';

interface IContextWrapperProps {
  children: React.ReactNode;
}

const ContextWrapper: React.FC<IContextWrapperProps> = function ({
  children,
}: Readonly<IContextWrapperProps>) {
  return (
    <AlertContextProvider>
      <WithAxios>
        <CurrentViewContextProvider>
          <AuthContextProvider>
            <DataContextProvider>
              <MakerMapContextProvider>
                <DrawerBoxInformationContextProvider>
                  {children}
                </DrawerBoxInformationContextProvider>
              </MakerMapContextProvider>
            </DataContextProvider>
          </AuthContextProvider>
        </CurrentViewContextProvider>
      </WithAxios>
    </AlertContextProvider>
  );
};

export default ContextWrapper;

import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';

import ConfigModal from 'components/ConfigModal';
import PasswordModal from 'components/PasswordModal';
import ProfileModal from 'components/ProfileModal';
import { useAuthContext } from 'context/AuthContext';
import useModal from 'hooks/useModal';
import styles from './styles.module.scss';

export default function ButtonAccount() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userInfo, logout } = useAuthContext();

  const [isConfigModalOpen, handleOpenConfigModal, handleCloseConfigModal] = useModal();
  const [isProfileModalOpen, handleOpenProfileModal, handleCloseProfileModal] = useModal();
  const [isPasswordModalOpen, handleOpenPasswordModal, handleClosePasswordModal] = useModal();
  return (
    <div className={styles.accountInfoContainer} id="accountContainer">
      <Tooltip title="Menu">
        <div className={styles.buttonBackground}>
          <IconButton aria-label="Menu do usuário" onClick={handleClick}>
            <PersonIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{ paper: { sx: { marginTop: '0.5rem' } } }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <MenuItem sx={{ gap: '0.5rem' }}>
          <Avatar /> <p className={styles.userEmail}>{userInfo.email}</p>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleOpenConfigModal(true)}>Opções</MenuItem>
        <MenuItem onClick={() => handleOpenProfileModal(true)}>Perfil</MenuItem>
        <MenuItem onClick={() => handleOpenPasswordModal(true)}>Alterar senha</MenuItem>
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </Menu>

      {isConfigModalOpen && (
        <ConfigModal isOpen={isConfigModalOpen} onClose={() => handleCloseConfigModal(false)} />
      )}
      {isProfileModalOpen && (
        <ProfileModal isOpen={isProfileModalOpen} onClose={() => handleCloseProfileModal(false)} />
      )}
      {isPasswordModalOpen && (
        <PasswordModal
          isOpen={isPasswordModalOpen}
          onClose={() => handleClosePasswordModal(false)}
        />
      )}
    </div>
  );
}

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { styled } from '@mui/system';

interface TableHeaderProps {
  orderBy: keyof typeof HeaderKeys | undefined;
  orderDirection: 'asc' | 'desc' | undefined;
  handleRequestSort: (key: keyof typeof HeaderKeys) => void;
}

interface Header {
  align: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
  key: keyof typeof HeaderKeys;
  label: string;
  notOrdered?: boolean;
}

export const HeaderKeys = {
  nome: 'nome',
  idade: 'idade',
  lastUpdate: 'lastUpdate',
  frequenciaCardiaca: 'frequenciaCardiaca',
  pressaoArterial: 'pressaoArterial',
  oximetria: 'oximetria',
  glicose: 'glicose',
  temperaturaCorporal: 'temperaturaCorporal',
  ultimaOcorrencia: 'ultimaOcorrencia',
  bateriaBaixa: 'bateriaBaixa',
  status: 'status',
};

export const headers: Header[] = [
  { align: 'left', key: 'nome', label: 'Nome' },
  { align: 'center', key: 'idade', label: 'Idade' },
  { align: 'left', key: 'lastUpdate', label: 'Visto' },
  { align: 'left', key: 'ultimaOcorrencia', label: 'Última Ocorrência', notOrdered: true },
  { align: 'right', key: 'status', label: 'Status', notOrdered: true },
];

const StyledTableHead = styled(TableHead)`
  & .MuiTableCell-root {
    background-color: #036666;
    color: white;
    white-space: nowrap;
  }
  & * {
    color: white !important;
  }
  & .Mui-active {
    color: white !important;
  }
  & .MuiTableSortLabel-root:hover > * {
    color: white !important;
    opacity: 1 !important;
  }
  & .MuiTableSortLabel-root:focus {
    color: white !important;
    opacity: 1 !important;
  }
`;

export default function TableHeader({
  orderDirection,
  orderBy,
  handleRequestSort,
}: Readonly<TableHeaderProps>) {
  function createSortHandler(key: keyof typeof HeaderKeys) {
    handleRequestSort(key);
  }

  return (
    <StyledTableHead>
      <TableRow>
        {headers.map((h) => (
          <TableCell align={h.align} key={h.key}>
            {!h.notOrdered ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === h.key}
                direction={orderBy === h.key ? orderDirection : undefined}
                onClick={() => createSortHandler(h.key)}
              >
                {h.label}
              </TableSortLabel>
            ) : (
              h.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'api';
import { storageParams } from 'config/enum';
import { UserOrganizationData } from 'interface/IUser';
import authenticationService from 'service/authenticationService';
import timezoneService from 'service/timezoneService';

function checkStoredTokenJWT() {
  return sessionStorage.getItem(storageParams.tokenJwt);
}

function checkCurrentUserEmail() {
  return sessionStorage.getItem(storageParams.currentUserEmail) ?? '';
}

interface AuthContextReturnType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  userInfo: UserInfo;
  logout: () => void;
}

interface AuthContextProps {
  children: React.ReactNode;
}

interface UserInfo {
  email: string;
  timeZone?: string;
  token: string;
  organizations: UserOrganizationData[];
}

const AuthContext = createContext({} as AuthContextReturnType);

export default function AuthContextProvider({ children }: Readonly<AuthContextProps>) {
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const navigate = useNavigate();

  const login = useCallback(
    (username: string, password: string) =>
      authenticationService.SignIn(username, password).then((resp) => {
        if (resp.status === 204) {
          setIsAuthenticated(true);
          navigate('/');
          timezoneService.getUserTimeZone(username).then((response) => {
            setUserInfo((old) => ({
              ...old,
              email: username,
              token: resp.headers['Authorization'],
              timeZone: response.timezone ?? 'America/Sao_Paulo',
              organizations: response.organizations,
            }));
            if (!sessionStorage.getItem(storageParams.organization)) {
              sessionStorage.setItem(
                storageParams.organization,
                response.organizations[0]?.organizationId
              );
            }

            sessionStorage.setItem(storageParams.timeZone, response.timezone);
          });
          sessionStorage.setItem(storageParams.currentUserEmail, username);
        }
      }),
    []
  );

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUserInfo({ email: '', token: '', timeZone: 'America/Sao_Paulo', organizations: [] });
    sessionStorage.removeItem(storageParams.tokenJwt);
    api.defaults.headers.Authorization = '';
    navigate('/login');
  }, []);

  useEffect(() => {
    const tokenJWT = checkStoredTokenJWT();

    if (tokenJWT) {
      api.defaults.headers.Authorization = `Bearer ${tokenJWT}`;
      setIsAuthenticated(true);
      navigate('/');
      timezoneService.getUserTimeZone(checkCurrentUserEmail()).then((response) => {
        setUserInfo((old) => ({
          ...old,
          email: checkCurrentUserEmail(),
          token: tokenJWT,
          timeZone: sessionStorage.getItem(storageParams.timeZone) ?? 'America/Sao_Paulo',
          organizations: response.organizations,
        }));
        if (sessionStorage.getItem(storageParams.organization) === 'undefined') {
          sessionStorage.setItem(
            storageParams.organization,
            response.organizations[0]?.organizationId
          );
        }
        sessionStorage.setItem(storageParams.timeZone, response.timezone);
      });
    }
  }, []);

  const providerValues = useMemo<AuthContextReturnType>(
    () => ({ isAuthenticated, login, userInfo, logout }),
    [isAuthenticated, login, userInfo, logout]
  );

  return <AuthContext.Provider value={providerValues}>{children}</AuthContext.Provider>;
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('AuthContext not in AuthContextProvider');
  }
  return context;
}

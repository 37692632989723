import { Autocomplete, TextField } from '@mui/material';
import { storageParams } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { useDataContext } from 'context/DataMapContext';
// import { useAuthContext } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
// import { useDataContext } from 'context/DataMapContext';
// import { useState } from 'react';

// eslint-disable-next-line react-hooks/rules-of-hooks

export default function OrganizationDrop() {
  const { userInfo } = useAuthContext();
  const { organization } = useDataContext();
  const [value, setValue] = React.useState<string | null>(
    userInfo.organizations?.find(
      (x) => x.organizationId === sessionStorage.getItem(storageParams.organization)
    )?.name ?? null
  );
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOption] = useState<string[]>([]);

  function setVisibleMarker(search: string) {
    const empresaSelect = userInfo.organizations?.find((x) => x.name === search);
    if (empresaSelect?.organizationId !== undefined) {
      organization(empresaSelect?.organizationId ?? '');
      sessionStorage.setItem(storageParams.organization, empresaSelect?.organizationId);
    }
  }

  useEffect(() => {
    if (value) {
      setVisibleMarker(value);
    }
  }, [value]);

  useEffect(() => {
    if (sessionStorage.getItem(storageParams.organization) !== 'undefined') {
      setOption(userInfo.organizations?.map((z) => z.name));
      setValue(
        userInfo.organizations?.find(
          (x) => x.organizationId === sessionStorage.getItem(storageParams.organization)
        )?.name ?? null
      );
    }
  }, [userInfo.organizations]);

  return (
    <div>
      {options && (
        <Autocomplete
          value={value}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="organization"
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField required {...params} placeholder="Empresa" size="small" />
          )}
        />
      )}
    </div>
  );
}

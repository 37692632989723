import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import GoogleMap from 'components/GoogleMap';
import Header from 'components/Header';
import config from 'config/config';
import { storageParams } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import MenuBoxInformation from 'pages/Dashboard/MenuBoxInformation';
import MenuCardsMeasurement from 'pages/Dashboard/MenuCardsMeasurement';
import MenuTableMeasurementRealTime from 'pages/Dashboard/MenuTableMeasurementRealTime';
import authenticationService from 'service/authenticationService';
import styles from './styles.module.scss';

export default function Dashboard() {
  const [isTableMeasurementOpen, setIsTableMeasurementOpen] = useState<boolean>(false);

  const { currentMapView } = useCurrentViewContext();
  const { userInfo } = useAuthContext();
  //   const { personToOpen } = Connector();

  useEffect(() => {
    switch (currentMapView) {
      case MapViewEnum.OPEN_CLOSE_DETAILS_MENU:
        setIsTableMeasurementOpen(true);
        break;
      case MapViewEnum.FIXED_DETAILS_MENU:
        setIsTableMeasurementOpen(true);
        break;
      case MapViewEnum.MENU_CARDS:
        setIsTableMeasurementOpen(false);
        break;
      case MapViewEnum.MENU_CARDS_DETAILS:
        setIsTableMeasurementOpen(false);
        break;
      case MapViewEnum.MAP_ONLY:
        setIsTableMeasurementOpen(false);
        break;
    }
  }, [currentMapView]);

  //   useEffect(() => {
  //     personToOpen((personId) => {
  //       if (currentMapView !== MapViewEnum.MAP_ONLY) {
  //         const personInfo = persons.find((x) => x.personId === personId);
  //         if (personInfo) {
  //           setPerson(personInfo);
  //           _marker.forEach((m) => {
  //             if (m.person.personId === personInfo.personId) {
  //               m.marker.setAnimation(google.maps.Animation.BOUNCE);
  //               m.marker.setZIndex(9999);
  //             } else {
  //               m.marker.setZIndex(1);
  //               m.marker.setAnimation(null);
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }, [_marker, currentMapView, persons]);

  useEffect(() => {
    const interval = setInterval(() => {
      const email = userInfo.email ?? sessionStorage.getItem(storageParams.currentUserEmail);
      authenticationService.refreshToken(email);
    }, parseInt(config.refresh_token));
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={styles.body}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.mapPage}>
        <MenuBoxInformation />
        <GoogleMap />
        <MenuTableMeasurementRealTime
          _tableMeasurement={isTableMeasurementOpen}
          setTableMeasurement={setIsTableMeasurementOpen}
        />
        <MenuCardsMeasurement />
      </div>
    </Box>
  );
}

import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import config from 'config/config';
import ContextWrapper from 'context';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import Forgotpassword from 'pages/Login/ForgotPassword';
import PrivateRoute from 'routes/PrivateRoute';

function App() {
  return (
    <BrowserRouter basename={config.publicUrl}>
      <ContextWrapper>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            }
          >
            <Route path="/" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </ContextWrapper>
    </BrowserRouter>
  );
}

export default App;

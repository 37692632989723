import api from 'api';
import { IOccurrence } from 'interface/IOccurrence';

const getOccurrence = async (personId: string): Promise<IOccurrence[]> => {
  const resp = await api.get(`/Persons/${personId}/Occurrence`);
  return resp.data;
};

const dismissOccurrence = async (personId: string | undefined, type: string): Promise<void> => {
  await api.post('/Occurrence/Dismiss', {
    personId: personId,
    type: type,
  });
};

const occurrenceService = {
  getOccurrence,
  dismissOccurrence,
};

export default occurrenceService;

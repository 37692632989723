import * as signalR from '@microsoft/signalr';
import { HttpTransportType } from '@microsoft/signalr';

import config from 'config/config';
import { storageParams } from 'config/enum';

export class Connector {
  public connection: signalR.HubConnection;
  public events: ((onMessageReceived: (message: string) => void) => void) | undefined;
  //   public personToOpen: (onMessageReceived: (message: string) => void) => void;
  static instance: Connector;
  public organizationId: string | null;
  constructor() {
    this.organizationId = sessionStorage.getItem(storageParams.organization);
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(config.urlWebSocket + `/${this.organizationId}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();
    if (this.organizationId !== null) {
      this.connection.start().catch((err) => console.log(err));
      this.events = (onMessageReceived) => {
        this.connection.on('SendMessage', (message) => {
          onMessageReceived(message);
        });
      };
    }

    // this.personToOpen = (onMessageReceived) => {
    //   this.connection.on('PersonOpenedOnAnotherSystem', (message) => {
    //     onMessageReceived(message);
    //   });
    // };
  }

  public newMessage = (messages: string) => {
    this.connection.send('newMessage', messages);
  };

  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;

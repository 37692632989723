import { IconButton, Tooltip } from '@mui/material';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import MapIcon from '@mui/icons-material/Map';

import { MapViewEnum } from 'enums/MapViewEnum';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import useWindow from 'hooks/useWindow';
import styles from './styles.module.scss';

export default function ChangeMapView() {
  const { changeCurrentMapView, currentMapView } = useCurrentViewContext();
  const windowSize = useWindow();

  return (
    <ul className={styles.changeViewMapList}>
      {!windowSize.isMobile && (
        <>
          <li className={styles.changeViewMapItem}>
            <Tooltip title="Mapa e tabela">
              <IconButton
                aria-label="Mapa e tabela"
                onClick={() => changeCurrentMapView(MapViewEnum.OPEN_CLOSE_DETAILS_MENU)}
                className={
                  currentMapView === MapViewEnum.OPEN_CLOSE_DETAILS_MENU ? styles.selected : ''
                }
              >
                <SpaceDashboardIcon />
              </IconButton>
            </Tooltip>
          </li>
          <li className={styles.changeViewMapItem}>
            <Tooltip title="Apenas tabela">
              <IconButton
                aria-label="Apenas tabela"
                onClick={() => changeCurrentMapView(MapViewEnum.FIXED_DETAILS_MENU)}
                className={currentMapView === MapViewEnum.FIXED_DETAILS_MENU ? styles.selected : ''}
              >
                <VerticalSplitIcon />
              </IconButton>
            </Tooltip>
          </li>
        </>
      )}

      <li className={styles.changeViewMapItem}>
        <Tooltip title="Apenas cards">
          <IconButton
            aria-label="Apenas cards"
            onClick={() => changeCurrentMapView(MapViewEnum.MENU_CARDS)}
            className={
              currentMapView === MapViewEnum.MENU_CARDS ||
              currentMapView === MapViewEnum.MENU_CARDS_DETAILS
                ? styles.selected
                : ''
            }
          >
            <ViewModuleIcon />
          </IconButton>
        </Tooltip>
      </li>
      <li className={styles.changeViewMapItem}>
        <Tooltip title="Apenas mapa">
          <IconButton
            aria-label="Apenas mapa"
            onClick={() => changeCurrentMapView(MapViewEnum.MAP_ONLY)}
            className={currentMapView === MapViewEnum.MAP_ONLY ? styles.selected : ''}
          >
            <MapIcon />
          </IconButton>
        </Tooltip>
      </li>
    </ul>
  );
}

/* eslint-disable @typescript-eslint/no-shadow */
import { createContext, useCallback, useContext, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';

interface AlertContextProviderProps {
  children: React.ReactNode;
}

interface AlertContextReturnType {
  notify: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error',
    duration?: 'short' | 'medium' | 'long' | 'always'
  ) => void;
}

export const AlertContext = createContext<AlertContextReturnType>({} as AlertContextReturnType);

export default function AlertContextProvider({ children }: Readonly<AlertContextProviderProps>) {
  const notify = useCallback(function (
    message: string,
    severity: 'error' | 'info' | 'success' | 'warning',
    duration: 'short' | 'medium' | 'long' | 'always' = 'medium'
  ) {
    let durationInSeconds: number | false;
    switch (duration) {
      case 'short':
        durationInSeconds = 2000;
        break;

      case 'medium':
        durationInSeconds = 4000;
        break;

      case 'long':
        durationInSeconds = 6000;
        break;

      case 'always':
        durationInSeconds = false;
        break;

      default:
        durationInSeconds = 4000;
    }

    toast(message, {
      type: severity,
      autoClose: durationInSeconds,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'colored',
      position: 'bottom-right',
      pauseOnHover: true,
      pauseOnFocusLoss: true,
    });
  }, []);

  const providerValues = useMemo<AlertContextReturnType>(() => ({ notify }), [notify]);

  return (
    <AlertContext.Provider value={providerValues}>
      {children}
      <ToastContainer />
    </AlertContext.Provider>
  );
}

export const useAlertContext = () => useContext<AlertContextReturnType>(AlertContext);

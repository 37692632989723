import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

import IMarker from 'interface/IMarker';

interface IMakerMapContext {
  markers: IMarker[];
  setMarkers: Dispatch<SetStateAction<IMarker[]>>;
  setGoogleMapInstance: Dispatch<google.maps.Map>;
  googleMapInstance: google.maps.Map | null;
}

interface MakerMapContextProps {
  children: React.ReactNode;
}

const MakerMapContext = createContext<IMakerMapContext | undefined>(undefined);

export default function MakerMapContextProvider({ children }: Readonly<MakerMapContextProps>) {
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [googleMapInstance, setGoogleMapInstance] = useState<google.maps.Map | null>(null);

  const providerValues = useMemo<IMakerMapContext>(
    () => ({
      markers,
      setMarkers,
      setGoogleMapInstance,
      googleMapInstance,
    }),
    [markers, setMarkers, setGoogleMapInstance, googleMapInstance]
  );

  return <MakerMapContext.Provider value={providerValues}>{children}</MakerMapContext.Provider>;
}

export function useMarkerMapContext() {
  const context = useContext(MakerMapContext);
  if (context === undefined) {
    throw new Error('AuthContext not in AuthContextProvider');
  }
  return context;
}

const {
  REACT_APP_URL_WEBSOCKET,
  REACT_APP_API_KEY_GOOGLE_MAPS,
  REACT_APP_API_URL_BASE,
  PUBLIC_URL,
  REACT_APP_API_KEY,
  REACT_APP_ID_MAP_GOOGLE_MAPS,
  REACT_APP_REFRESH_TOKEN,
} = process.env;

const config = {
  urlWebSocket: REACT_APP_URL_WEBSOCKET ?? '',
  apiKeyGoogleMaps: REACT_APP_API_KEY_GOOGLE_MAPS ?? '',
  apiUrlBase: REACT_APP_API_URL_BASE ?? '/appapi/api',
  publicUrl: PUBLIC_URL,
  apiKey: REACT_APP_API_KEY,
  idMapGoogleMap: REACT_APP_ID_MAP_GOOGLE_MAPS,
  refresh_token: REACT_APP_REFRESH_TOKEN ?? '1140000',
};

export default config;

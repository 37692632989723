import { useEffect } from 'react';

import AppStoreBadge from 'assets/badge_app_store.png';
import GooglePlayBadge from 'assets/badge_google_play.png';
import BaseModal from 'components/BaseModal';
import { messagesModal, storageParams } from 'config/enum';
import useModal from 'hooks/useModal';
import useWindow from 'hooks/useWindow';
import styles from './styles.module.scss';

function getViewedMessagesFromSessionStorage() {
  const messages = sessionStorage.getItem(storageParams.viewedMessages);
  if (messages) {
    return JSON.parse(messages) as string[];
  }
  return [] as string[];
}

function addViewedMessageInSessionStorage(message: string) {
  const messages = getViewedMessagesFromSessionStorage();

  if (!messages.includes(message)) {
    messages.push(message);
    sessionStorage.setItem(storageParams.viewedMessages, JSON.stringify(messages));
  }
}

export default function DownloadAppModal() {
  const [isModalOpen, handleOpenModal, handleCloseModal] = useModal();

  const windowSize = useWindow();

  function handleOnCloseModal() {
    addViewedMessageInSessionStorage(messagesModal.downloadApp);
    handleCloseModal(true);
  }

  useEffect(() => {
    if (windowSize.isMobile) {
      const messages = getViewedMessagesFromSessionStorage();

      if (!messages.includes(messagesModal.downloadApp)) {
        handleOpenModal(true);
      }
    } else {
      handleCloseModal(true);
    }
  }, [windowSize.isMobile]);
  return (
    <BaseModal title="Baixar aplicativo" isOpen={isModalOpen} onClose={handleOnCloseModal}>
      <div className={styles.downloadAppContainer}>
        <p className={styles.message}>
          Caro usuário, você está acessando um site que tem algumas limitações em dispositivos
          móveis. Para uma melhor experiência, baixe o nosso aplicativo. Clique em um dos botões
          abaixo para baixar na App Store ou Google Play.
        </p>
        <div className={styles.badgeContainer}>
          <img src={GooglePlayBadge} alt="" className={styles.googleBadge} />
          <img src={AppStoreBadge} alt="" className={styles.appleBadge} />
        </div>
      </div>
    </BaseModal>
  );
}

import * as yup from 'yup';

export const LoginSchema = yup
  .object({
    email: yup.string().email('O Email inserido não é válido').required('O Email é obrigatório'),
    password: yup.string().required('A senha é obrigatória'),
  })
  .required();

export const RecoverySchema = yup
  .object({
    email: yup.string().email('O Email inserido não é válido').required('O Email é obrigatório'),
  })
  .required();

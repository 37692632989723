import { IPersonInfo } from 'interface/IPersonInfo';
import styles from './styles.module.scss';

interface IProps {
  person: IPersonInfo | null;
}

export default function InfoWindow({ person }: Readonly<IProps>) {
  return (
    <div className={styles.personInformationDivContainer}>
      <div className={styles.personInformationDivNameContainer}>
        <p className={styles.personName}>{person?.name}</p>
      </div>
      <div className={styles.personInformationDivPhotoContainer}>
        <img
          src={
            person?.urlPhoto === '' || person?.urlPhoto === undefined
              ? require('assets/generic_avatar.png')
              : person?.urlPhoto
          }
          alt={person?.name}
          className={styles.photo}
        ></img>
      </div>
    </div>
  );
}

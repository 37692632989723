import api from 'api';
import { UserUpdatePassword } from 'components/PasswordModal/helpers';

function recoverPassword(username: string) {
  const data = {
    email: username,
  };
  return api.post('/User/password', data);
}
function updatePassword(data: UserUpdatePassword) {
  return api.patch('/User/resetPassword', data);
}

const userService = {
  recoverPassword,
  updatePassword,
};

export default userService;

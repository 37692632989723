import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import LoginBackground from 'assets/login_background.png';
import LogoPca from 'assets/logo_pca.png';
import DownloadAppModal from 'components/DownloadAppModal';
import { storageParams } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { LoginPost } from 'interface/ILogin';
import { LoginSchema } from 'pages/Login/schemas/Login';
import { Link } from 'react-router-dom';
import versionService from 'service/versionService';
import styles from './styles.module.scss';

function returnSavedUsername() {
  return localStorage.getItem(storageParams.savedUsername) ?? '';
}

export default function Login() {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [saveUsername, setSaveUsername] = useState<boolean>(!!returnSavedUsername());
  const [version, setVersion] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: returnSavedUsername(),
    },
  });

  const { login } = useAuthContext();

  async function handleFormSubmit(e: LoginPost) {
    if (saveUsername) {
      localStorage.setItem('savedUsername', e.email);
    } else {
      localStorage.removeItem('savedUsername');
    }

    setIsLoggingIn(true);

    try {
      await login(e.email, e.password);
    } catch (error) {
      setIsLoggingIn(false);
    }

    setIsLoggingIn(false);
  }

  useEffect(() => {
    versionService.getVersion().then((res) => {
      setVersion(res.data.version);
    });
  }, []);

  const onChangeSetSaveUsername = useCallback(() => {
    setSaveUsername(!saveUsername);
  }, [saveUsername]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginContainer}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.formContainer}>
          <div className={styles.logoContainer}>
            <img src={LogoPca} alt="Logo" className={styles.logo} />
          </div>

          <fieldset className={styles.fieldset}>
            <legend className={styles.title}>Autenticação</legend>
            <span className={styles.subtitle}>Entre com seu endereço de e-mail e senha.</span>
            <TextField
              id="email"
              type="email"
              label="E-mail"
              fullWidth
              margin="normal"
              error={!!errors.email}
              disabled={isLoggingIn}
              {...register('email')}
            />
            <TextField
              id="password"
              type="password"
              label="Senha"
              fullWidth
              margin="normal"
              error={!!errors.password}
              disabled={isLoggingIn}
              {...register('password')}
            />
          </fieldset>

          <div className={styles.buttons}>
            <FormControlLabel
              className={saveUsername ? styles.rememberMeChecked : ''}
              control={
                <Checkbox
                  checked={saveUsername}
                  onChange={onChangeSetSaveUsername}
                  disabled={isLoggingIn}
                />
              }
              label="Lembrar de mim"
              disabled={isLoggingIn}
            />

            <Button
              type="submit"
              className={styles.buttonLogin}
              variant="contained"
              disabled={isLoggingIn}
            >
              {isLoggingIn ? 'Entrando...' : 'Entrar'}
            </Button>
          </div>

          <div className={styles.linkContainer}>
            <Link to="/forgotpassword" className={styles.link}>
              Esqueceu a senha?
            </Link>
          </div>
        </form>
        <footer className={styles.footer}>
          @ 2023 PCA Engenharia de Software Ltda. - All Rights Reserved - Version {version}
        </footer>
      </div>
      <div className={styles.imageContainer}>
        <img src={LoginBackground} alt="Plano de fundo Login" className={styles.imageBackground} />
      </div>

      <DownloadAppModal />
    </div>
  );
}

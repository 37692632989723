import { Loader } from '@googlemaps/js-api-loader';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDomServer from 'react-dom/server';

import InfoWindow from 'components/GoogleMap/InfoWindow';
import config from 'config/config';
import { Criticality, Events } from 'config/enum';
import { useCurrentViewContext } from 'context/CurrentViewContext';
import { removeAccentuation, useDataContext } from 'context/DataMapContext';
import { useMarkerMapContext } from 'context/MarkerMapContext';
import { MapViewEnum } from 'enums/MapViewEnum';
import IMarker from 'interface/IMarker';
import { IPersonInfo } from 'interface/IPersonInfo';
import styles from './styles.module.scss';

export default function GoogleMap() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  const { filteredPersons, search, setSelectedPerson } = useDataContext();
  const { markers, setMarkers, googleMapInstance, setGoogleMapInstance } = useMarkerMapContext();

  const { currentMapView } = useCurrentViewContext();

  const ref = useRef<HTMLDivElement | null>(null);

  function returnMarker(parseData: IPersonInfo[]) {
    const lmarkers: IMarker[] = markers;

    parseData?.forEach((person) => {
      const index = lmarkers?.findIndex((x) => x.person.personId === person.personId);

      let icon = require('assets/map_icon_online.png');
      if (person.currentActivityLevel === Criticality.Critical) {
        icon = require('assets/map_icon_emergency.png');
      } else if (person.currentActivityLevel === Criticality.Warning) {
        icon = require('assets/map_icon_warning.png');
      } else if (!person.online) {
        icon = require('assets/map_icon_offline.png');
      }

      if (index >= 0) {
        const personExist = lmarkers[index];

        personExist.events.forEach((ev, index) => {
          ev.remove();
          personExist.events.splice(index, 0);
        });

        personExist?.marker.setPosition({
          lat: person.latitude,
          lng: person.longitude,
        });

        personExist?.marker.setIcon(icon);

        const events = configMarker(personExist?.marker, person);

        lmarkers[index] = {
          marker: personExist?.marker,
          person: person,
          events: events,
        };

        setMarkers(lmarkers);
      } else {
        const gmarker = new google.maps.Marker({
          position: { lat: person.latitude, lng: person.longitude },
          icon: icon,
        });

        const events = configMarker(gmarker, person);

        const marker: IMarker = {
          events: events,
          marker: gmarker,
          person,
        };

        lmarkers.push(marker);
        setMarkers(lmarkers);
      }

      markers.forEach((p) => {
        p.marker.setVisible(false);
      });

      const personSeach = searchPersonName(markers, search);
      personSeach.forEach((p) => {
        p.marker.setVisible(true);
      });
    });
  }

  function searchPersonName(
    list: { person: IPersonInfo; marker: google.maps.Marker }[],
    nameSearch: string
  ) {
    return list.filter(function (p) {
      return (
        removeAccentuation(p.person.name)
          .toLowerCase()
          .includes(removeAccentuation(nameSearch.toLowerCase())) ||
        p.person.occurrencesTypes.includes(Events.Sos) ||
        p.person.occurrencesTypes.includes(Events.Fall)
      );
    });
  }

  const configMarker = (marker: google.maps.Marker, person: IPersonInfo) => {
    const output = document.createElement('div');
    const staticElement = ReactDomServer.renderToStaticMarkup(<InfoWindow person={person} />);
    output.innerHTML = staticElement;

    const clickEvent = marker.addListener('click', () => {
      setSelectedPerson(person);

      markers.forEach((m) => {
        m.marker.setZIndex(1);
        m.marker.setAnimation(null);
      });

      marker.setAnimation(google.maps.Animation.BOUNCE);
      marker.setZIndex(9999);
    });

    const mouseOverEvent = marker.addListener('mouseover', () => {
      infoWindow?.setContent(output);
      infoWindow?.setOptions({ ariaLabel: person.name });
      infoWindow?.open({
        anchor: marker,
        map: googleMapInstance,
      });
    });

    const mouseOutEvent = marker.addListener('mouseout', () => {
      infoWindow?.close();
    });

    marker.setMap(googleMapInstance);

    return [clickEvent, mouseOverEvent, mouseOutEvent];
  };

  const updateMarker = useCallback(() => {
    if (!isLoading) {
      returnMarker(filteredPersons);
    }
  }, [isLoading, filteredPersons, returnMarker, search]);

  useEffect(() => {
    updateMarker();
  }, [updateMarker]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.apiKeyGoogleMaps,
      version: 'beta',
    });

    const zoom = 17.5;
    loader.importLibrary('maps').then(async () => {
      setInfoWindow(
        new google.maps.InfoWindow({
          maxWidth: 200,
          minWidth: 200,
          disableAutoPan: true,
        })
      );

      const instance = new google.maps.Map(ref.current!, {
        center: new google.maps.LatLng(-20.7930992, -49.2950564),
        zoom: zoom,
        mapTypeId: 'hybrid',
        heading: 91,
        tilt: 0,
        mapId: config.idMapGoogleMap,
        zoomControl: false,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        // restriction: {
        //   strictBounds: false,
        //   latLngBounds: new google.maps.LatLngBounds(
        //     new google.maps.LatLng(-20.797453, -49.29814),
        //     new google.maps.LatLng(-20.788388, -49.289993)
        //   ),
        // },
      });
      setGoogleMapInstance(instance);
      setIsLoading(false);
    });
  }, []);

  return (
    <div
      ref={ref}
      id="map"
      className={
        currentMapView === MapViewEnum.OPEN_CLOSE_DETAILS_MENU
          ? styles.myMapFullDetailsMenu
          : styles.myMapFull
      }
    ></div>
  );
}

import { Dispatch, SetStateAction, createContext, useContext, useMemo, useState } from 'react';

interface IDrawerBoxInformationContext {
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}

interface DrawerBoxInformationContextProps {
  children: React.ReactNode;
}

const DrawerBoxInformationContext = createContext<IDrawerBoxInformationContext | undefined>(
  undefined
);

export default function DrawerBoxInformationContextProvider({
  children,
}: Readonly<DrawerBoxInformationContextProps>) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const providerValues = useMemo<IDrawerBoxInformationContext>(
    () => ({
      openDrawer,
      setOpenDrawer,
    }),
    [openDrawer, setOpenDrawer]
  );

  return (
    <DrawerBoxInformationContext.Provider value={providerValues}>
      {children}
    </DrawerBoxInformationContext.Provider>
  );
}

export function useDrawerBoxInformationContext() {
  const context = useContext(DrawerBoxInformationContext);
  if (context === undefined) {
    throw new Error('AuthContext not in AuthContextProvider');
  }
  return context;
}

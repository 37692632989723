import { measurement } from 'config/enum';

function formatValuesMeasurement(value: any, measurementType: string) {
  if (value) {
    const measure = JSON.parse(value);
    if (measure) {
      switch (measurementType) {
        case measurement.heartRate:
          return `${measure?.bpm} bpm`;
        case measurement.bloodPressure:
          return `${measure.systolic.toString()}/${measure.diastolic.toString()}  mmHg`;
        case measurement.oximetry:
          return `${measure.spo2}%`;
        case measurement.bloodSugar:
          return `${measure.bloodSugar} mg/dL`;
        case measurement.bodyTemperature:
          return `${measure.temperature} °C`;
        case measurement.steps:
          return `${measure.steps}`;
        default:
          return '-';
      }
    }
    return '-';
  }
  return '-';
}

function formatValuesMeasurementHistory(value: any, measurementType: string) {
  if (value) {
    const measure = JSON.parse(value);
    if (measure) {
      switch (measurementType) {
        case measurement.heartRate:
          return parseInt(measure?.bpm);
        case measurement.bloodPressure:
          return parseInt(measure.systolic);
        case measurement.oximetry:
          return parseInt(measure.spo2);
        case measurement.bloodSugar:
          return parseInt(measure.bloodSugar);
        case measurement.bodyTemperature:
          return parseInt(measure.temperature);
        case measurement.steps:
          return parseInt(measure.steps);
        default:
          return 0;
      }
    }
    return 0;
  }
  return 0;
}

function formatValuesDiastolic(value: any, measurementType: string) {
  if (value) {
    const measure = JSON.parse(value);
    if (measure) {
      switch (measurementType) {
        case measurement.bloodPressure:
          return parseInt(measure.diastolic);
        default:
          return 0;
      }
    }
    return 0;
  }
  return 0;
}
export { formatValuesDiastolic, formatValuesMeasurement, formatValuesMeasurementHistory };

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/AuthContext';

export default function PrivateRoute({ children }: { children: React.JSX.Element }) {
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('login');
    }
  }, [isAuthenticated, navigate]);

  return children;
}

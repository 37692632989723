import api from 'api';
import { IUser } from 'interface/IUser';

const getUserTimeZone = async (email: string): Promise<IUser> => {
  const resp = await api.get('/Timezone', { params: { email: email } });
  return resp.data;
};

const getTimeZone = async () => {
  const resp = await api.get('/Timezone/list');
  return resp.data;
};

const postTimeZone = async (email: string, timeZone: string): Promise<void> => {
  await api.patch('/Timezone', {
    email: email,
    timeZone: timeZone,
  });
};

const timezoneService = {
  getTimeZone,
  postTimeZone,
  getUserTimeZone,
};

export default timezoneService;

import { CircularProgress, Divider } from '@mui/material';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import BloodPressure from 'components/BoxInformation/Charts/BloodPressure';
import BloodSugar from 'components/BoxInformation/Charts/BloodSugar';
import BodyTemperature from 'components/BoxInformation/Charts/BodyTemperature';
import FilterDate from 'components/BoxInformation/Charts/FilterDate';
import HeartRate from 'components/BoxInformation/Charts/HeartRate';
import Oximetry from 'components/BoxInformation/Charts/Oximetry';
import Steps from 'components/BoxInformation/Charts/Steps';
import { measurement, monthNames } from 'config/enum';
import { useAuthContext } from 'context/AuthContext';
import { IDataChart } from 'interface/IDataChart';
import { IMeasurementHistory } from 'interface/IMeasurement';
import { IPersonInfo } from 'interface/IPersonInfo';
import { formatDateRequest, formatTimestampForDate } from 'service/dateService';
import {
  formatValuesDiastolic,
  formatValuesMeasurementHistory,
} from 'service/formatValuesMeasurementService';
import measurementService from 'service/measurementService';
import styles from './style.module.scss';

interface IProps {
  person: IPersonInfo | null;
  measurementType: string;
  selectedPerson: IPersonInfo | null;
}

interface IMaxMin {
  min: number;
  max: number;
}

export interface CommonChartProps {
  dataChart: IDataChart[];
  currentDateString: string;
  changeFilter: (data: any) => void;
  typeDate?: string;
  setMinMax: IMaxMin;
}

function returnDataChartByMeasurementHistory(
  mh: IMeasurementHistory[],
  typeDate: string,
  timezone?: string
): IDataChart[] {
  return mh.map((m) => ({
    label: formatTimestampForDate(m.creationDate, typeDate, timezone),
    data: formatValuesMeasurementHistory(m.measurementValue, m.measurementType),
    data2: formatValuesDiastolic(m.measurementValue, m.measurementType),
  }));
}

export function getFirstAndLastDayOfWeek(date: Date): [Date, Date] {
  const start = startOfWeek(date);
  const end = endOfWeek(date);

  return [start, end];
}
export function getFirstAndLastDayOfMonth(date: Date): [Date, Date] {
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  return [start, end];
}

export function returnInfoDate(selectedDate: dayjs.Dayjs, typeDate: string) {
  if (selectedDate) {
    if (typeDate === 'diario') {
      return `${selectedDate.date()} 
        de ${monthNames[selectedDate.month()]} 
        de ${selectedDate.year()}`;
    } else if (typeDate === 'mensal') {
      const [firstDayOfMonth, lastDayOfMonth] = getFirstAndLastDayOfMonth(selectedDate.toDate());
      return `
        ${firstDayOfMonth.getDate()} a ${lastDayOfMonth.getDate()} 
        de ${monthNames[lastDayOfMonth.getMonth()]} 
        de ${lastDayOfMonth.getFullYear()}`;
    } else {
      const [firstDayOfWeek, lastDayOfWeek] = getFirstAndLastDayOfWeek(selectedDate.toDate());
      return `
        ${firstDayOfWeek.getDate()} a ${lastDayOfWeek.getDate()} 
        de ${monthNames[lastDayOfWeek.getMonth()]} 
        de ${lastDayOfWeek.getFullYear()}`;
    }
  }
  return '';
}

export default function Charts({ measurementType, person, selectedPerson }: IProps) {
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState<IDataChart[]>([]);
  const [chartDate, setChartDate] = useState<boolean>(false);
  const [dateInterval, setDateInterval] = useState<[Date, Date]>([
    dayjs().toDate(),
    dayjs().toDate(),
  ]);
  const [minMax, setMinMax] = useState<any>();
  const [inter, setInter] = useState<Dayjs>(dayjs());
  const [typeDate, setTypeDate] = useState<string>('diario');
  const { userInfo } = useAuthContext();
  useEffect(() => {
    setDateInterval([dayjs().toDate(), dayjs().toDate()]);
  }, [selectedPerson, person]);

  useEffect(() => {
    async function fetchDataChart() {
      const [dateStart, dateEnd] = dateInterval;
      try {
        setLoading(true);

        const measurementHistoryByType = await measurementService.measurementHistory(
          person!.personId,
          formatDateRequest(dateStart),
          formatDateRequest(dateEnd),
          measurementType
        );
        setChartDate(false);

        if (typeDate === 'semanal') {
          const weeklyDataChart = returnDataChartByMeasurementHistory(
            measurementHistoryByType,
            typeDate,
            userInfo.timeZone
          );
          const min = Math.min(...weeklyDataChart.map((item) => item.data));
          const max = Math.max(...weeklyDataChart.map((item) => item.data));
          setDataChart(weeklyDataChart);
          setMinMax({ min: min, max: max });
        } else if (typeDate === 'mensal') {
          const monthDataChart = returnDataChartByMeasurementHistory(
            measurementHistoryByType,
            typeDate,
            userInfo.timeZone
          );

          const min = Math.min(...monthDataChart.map((item) => item.data));
          const max = Math.max(...monthDataChart.map((item) => item.data));

          setDataChart(monthDataChart);
          setMinMax({ min: min, max: max });
        } else {
          const dailyDataChart = returnDataChartByMeasurementHistory(
            measurementHistoryByType,
            typeDate,
            userInfo.timeZone
          );

          setDataChart(dailyDataChart);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }

    if (person && measurementType && dateInterval && !loading) {
      fetchDataChart();
    }
  }, [measurementType, person, dateInterval]);

  const changeDt = (data: any) => {
    if (!data[0]?.label.includes(':')) {
      const dateParts = data
        .map((e: { label: any }) => e.label)
        .toString()
        .split('/');
      const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      setChartDate(true);
      setTypeDate('diario');
      setInter(dayjs(dateObject));
      setDateInterval([dateObject, dateObject]);
    }
  };

  function returnChart() {
    switch (measurementType) {
      case measurement.heartRate:
        return (
          <HeartRate
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      case measurement.bloodPressure:
        return (
          <BloodPressure
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      case measurement.bloodSugar:
        return (
          <BloodSugar
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      case measurement.bodyTemperature:
        return (
          <BodyTemperature
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      case measurement.oximetry:
        return (
          <Oximetry
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      case measurement.steps:
        return (
          <Steps
            setMinMax={minMax}
            changeFilter={(data) => changeDt(data)}
            typeDate={typeDate}
            dataChart={dataChart}
            currentDateString={returnInfoDate(dayjs(dateInterval[0]), typeDate)}
          />
        );
      default:
        return null;
    }
  }

  return measurementType ? (
    <>
      <Divider />
      <div className={styles.chartContainer}>
        <FilterDate
          measurementType={measurementType}
          setDateInterval={setDateInterval}
          setTypeDate={setTypeDate}
          typeDate={typeDate}
          chartDate={chartDate}
          intervalDate={inter}
        />
        <div className={styles.chart}>
          <div className={loading ? styles.loadingChartWrapper : undefined}>{returnChart()}</div>
          {loading && (
            <div className={styles.loadingContainer}>
              <CircularProgress className={styles.loadingIndicator} />
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
}

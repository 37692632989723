import IMarker from 'interface/IMarker';
import { IPersonInfo } from 'interface/IPersonInfo';

export function selectMarkerOnMap(
  googleMapsInstance: google.maps.Map | null,
  markers: IMarker[],
  selectedPerson: IPersonInfo | null
) {
  if (googleMapsInstance && selectedPerson) {
    markers.forEach((m) => {
      if (m.person.personId === selectedPerson.personId) {
        m.marker.setAnimation(google.maps.Animation.BOUNCE);
        m.marker.setZIndex(9999);

        const position = m.marker.getPosition();
        if (position) {
          googleMapsInstance.setCenter(position);
        }
      } else {
        m.marker.setZIndex(1);
        m.marker.setAnimation(null);
      }
    });
  }
}

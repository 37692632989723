import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@mui/material';
import LoginBackground from 'assets/login_background.png';
import LogoPca from 'assets/logo_pca.png';
import DownloadAppModal from 'components/DownloadAppModal';
import { useAlertContext } from 'context/AlertContext';
import { RecoveryPassword } from 'interface/ILogin';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import userService from 'service/userService';
import versionService from 'service/versionService';
import { RecoverySchema } from '../schemas/Login';
import styles from './styles.module.scss';

export default function Forgotpassword() {
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [send, setSend] = useState<boolean>(false);
  const { notify } = useAlertContext();
  const [version, setVersion] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RecoverySchema),
  });

  async function handleFormSubmit(e: RecoveryPassword) {
    setIsLoggingIn(true);

    try {
      await userService.recoverPassword(e.email).then(() => {
        setSend(true);
        notify('Verifique sua caixa de entrada!', 'success', 'long');
      });
    } catch (error) {
      setIsLoggingIn(false);
    }

    setIsLoggingIn(false);
  }
  useEffect(() => {
    versionService.getVersion().then((res) => {
      setVersion(res.data.version);
    });
  }, []);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginContainer}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.formContainer}>
          <div className={styles.logoContainer}>
            <img src={LogoPca} alt="Logo" className={styles.logo} />
          </div>

          <fieldset className={styles.fieldset}>
            <legend className={styles.title}>Esqueci minha senha</legend>
            {send ? (
              <span className={styles.subtitle}>Verifique sua caixa de entrada!</span>
            ) : (
              <span className={styles.subtitle}>
                Entre com seu endereço de e-mail para enviarmos uma nova senha de acesso.
              </span>
            )}
            <TextField
              id="email"
              type="email"
              label="E-mail"
              fullWidth
              margin="normal"
              error={!!errors.email}
              disabled={isLoggingIn}
              {...register('email')}
            />
          </fieldset>
          <div className={styles.buttons}>
            {!send && (
              <div className="k-form-buttons">
                <Button type="submit" className={styles.buttonLogin} variant="contained">
                  Enviar
                </Button>
              </div>
            )}
          </div>
          <div className={styles.ContainerLink}>
            <Link to="/login" className={styles.Link}>
              Voltar para a tela de Login
            </Link>
          </div>
        </form>
        <footer className={styles.footer}>
          @ 2023 PCA Engenharia de Software Ltda. - All Rights Reserved - Version {version}
        </footer>
      </div>
      <div className={styles.imageContainer}>
        <img src={LoginBackground} alt="Plano de fundo Login" className={styles.imageBackground} />
      </div>

      <DownloadAppModal />
    </div>
  );
}

import { Box } from '@mui/material';
import Chart from 'chart.js/auto';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { CommonChartProps } from 'components/BoxInformation/Charts';
import { eType, returnNameValueIcon } from 'components/BoxInformation/TableMeasurement';
import { measurement } from 'config/enum';
import styles from '../style.module.scss';

export default function Oximetry({
  dataChart,
  currentDateString,
  changeFilter,
  typeDate,
  setMinMax,
}: Readonly<CommonChartProps>) {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [cont, setCont] = useState<any>();

  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y + '%';
              }
              return label;
            },
            footer: function (context: any) {
              setCont(context);
              if (setMinMax && typeDate === 'semanal') {
                return `Min:${setMinMax.min}%\nMax:${setMinMax.max}%`;
              }
            },
          },
        },
      },
      elements: {
        bar: {
          backgroundColor: (ctx: any) => {
            if (ctx.raw >= 93) {
              return 'green';
            }
            return 'red';
          },
        },
      },
    };
  }, [typeDate, setMinMax]);

  const chartData = useMemo(() => {
    return {
      labels: dataChart.map((data) => data.label.toString()),
      datasets: [
        {
          label: 'Oximetria',
          data: dataChart.map((data) => data.data),
          borderWidth: 1,
        },
      ],
    };
  }, [dataChart]);

  useEffect(() => {
    let newChart: any;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (ctx) {
        newChart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: chartOptions,
        });
      }
    }

    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [chartData, chartOptions]);

  const onClick = () => {
    if (cont && typeDate === 'semanal') {
      if (cont[0].label.length > 5) {
        changeFilter(cont);
      }
    }
  };

  return (
    <>
      <Box className={styles.chartLabelContainer}>
        <p>
          {returnNameValueIcon(measurement.oximetry, eType.Icon)}
          {returnNameValueIcon(measurement.oximetry, eType.Title)}
        </p>
        <p>{currentDateString}</p>
      </Box>
      <div style={{ height: '170px', position: 'relative' }}>
        <div style={{ height: '150px', position: 'relative' }}>
          <Bar id="graphic" options={chartOptions} data={chartData} onClick={onClick} />
        </div>
      </div>
    </>
  );
}

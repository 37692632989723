import { Box } from '@mui/material';
import Chart from 'chart.js/auto';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';

import { CommonChartProps } from 'components/BoxInformation/Charts';
import { eType, returnNameValueIcon } from 'components/BoxInformation/TableMeasurement';
import { measurement } from 'config/enum';
import { IDataChart } from 'interface/IDataChart';
import styles from '../style.module.scss';

export default function Steps({
  dataChart,
  currentDateString,
  changeFilter,
  typeDate,
  setMinMax,
}: Readonly<CommonChartProps>) {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chartSteps, setChartSteps] = useState<IDataChart[]>(dataChart);
  const [cont, setCont] = useState<any>();

  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,

      plugins: {
        legend: {
          display: typeDate === 'diario' ? true : false,
          labels: {
            generateLabels: (chart: any) => {
              if (typeDate === 'diario') {
                const datasets = chart.data.datasets;

                const dados = datasets[0]?.data?.map((data: any, i: any) => ({
                  text: `${chart.data.labels[i]} ${data}`,
                  fillStyle: 'rgb(255 165 0 / 50%)',
                  index: i,
                }));
                if (dados[0]?.text !== 'undefined undefined') {
                  return dados;
                }
              }
            },
          },
        },
        tooltip: {
          callbacks: {
            footer: function (context: any) {
              setCont(context);
              if (setMinMax && typeDate !== 'diario') {
                return `Min:${setMinMax.min}\nMax:${setMinMax.max}`;
              }
            },
          },
        },
      },
    };
  }, [typeDate, setMinMax]);

  useEffect(() => {
    const dateWithCountTotal: {
      [key: string]: {
        count: number[];
        total: number;
      };
    } = {};
    dataChart.forEach((item: IDataChart) => {
      dateWithCountTotal[item.label] = dateWithCountTotal[item.label] || {
        count: [],
        total: 0,
      };
    });

    setChartSteps(
      Object.entries(dateWithCountTotal).map((entry) => {
        if (typeDate === 'diario') {
          entry[1].total = Math.max(...dataChart.map((x) => x.data));
          entry[0] = '';
        } else {
          dataChart.forEach((item: IDataChart) => {
            if (item.label === entry[0]) {
              dateWithCountTotal[item.label].count.push(item.data);
            }
          });
          dataChart.forEach((item: IDataChart) => {
            if (item.label === entry[0]) {
              dateWithCountTotal[item.label].total = Math.max(
                ...dateWithCountTotal[item.label].count
              );
            }
          });
        }
        return {
          data: entry[1].total,
          label: entry[0],
        };
      })
    );
  }, [dataChart, typeDate]);

  const chartData = useMemo(() => {
    return {
      labels:
        typeDate === 'diario' && chartSteps
          ? [chartSteps[0]?.label]
          : chartSteps.map((data) => data.label.toString()),
      datasets: [
        {
          label: 'Passos',
          data:
            typeDate === 'diario' && chartSteps
              ? [chartSteps[0]?.data]
              : chartSteps.map((data) => data.data),
          backgroundColor: 'rgb(255 165 0 / 50%)',
          borderColor: 'rgb(255 165 0 / 50%)',
          borderWidth: 1,
          fill: true,
        },
      ],
    };
  }, [chartSteps]);

  useEffect(() => {
    let newChart: any;

    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      if (ctx) {
        newChart = new Chart(ctx, {
          type: typeDate !== 'diario' ? 'bar' : 'doughnut',
          data: chartData,
          options: chartOptions,
        });
      }
    }
    return () => {
      if (newChart) {
        newChart.destroy();
      }
    };
  }, [chartData, chartOptions]);

  const onClick = () => {
    if (cont && typeDate !== 'diario') {
      if (cont[0].label.length > 5) {
        changeFilter(cont);
      }
    }
  };

  return (
    <>
      <Box className={styles.chartLabelContainer}>
        <p>
          {returnNameValueIcon(measurement.steps, eType.Icon)}
          {returnNameValueIcon(measurement.steps, eType.Title)}
        </p>
        {currentDateString}
      </Box>
      <div style={{ height: '170px', position: 'relative' }}>
        <div style={{ height: '150px', position: 'relative' }}>
          {typeDate !== 'diario' ? (
            <Bar id="graphic" options={chartOptions} data={chartData} onClick={onClick} />
          ) : (
            <Doughnut id="graphic" options={chartOptions} data={chartData} onClick={onClick} />
          )}
        </div>
      </div>
    </>
  );
}
